import React from 'react';
import { Checkbox } from '@johnlewispartnership/wtr-ingredients/ingredients/Checkbox';
import { TypographySecondary } from '@johnlewispartnership/wtr-ingredients/foundations/typography/TypographySecondary';
import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography/Typography';
import { useWtrSelector } from 'redux/hooks';
import styles from './index.scss';
import {
  getNumberOfSelectedShoppableProducts,
  getTotalPriceOfShoppableProducts,
} from '../../../../redux/modules/recipes/selectors';
import { formatAsPounds } from '../../../../utils/currency';

export interface AccordionRecipeTitleProps {
  recipeId: string;
  title: string;
  checked: boolean;
  toggleIngredients?: (checked: boolean) => void;
}

const AccordionRecipeTitle = ({
  recipeId,
  title,
  checked = true,
  toggleIngredients,
}: AccordionRecipeTitleProps) => {
  const numberOfSelectedShoppableProducts = useWtrSelector(state =>
    getNumberOfSelectedShoppableProducts(state, recipeId),
  );

  const totalPriceOfSelectedShoppableProducts = useWtrSelector(state =>
    getTotalPriceOfShoppableProducts(state, recipeId, true),
  );

  return (
    <div
      className={styles.accordionTitleWrapper}
      data-testid={`accordion-recipe-title-${recipeId}`}
    >
      <div className={styles.recipeTitleWrapper}>
        <Checkbox
          checked={checked}
          className={styles.recipeCheckbox}
          onClick={(event: React.MouseEvent<HTMLInputElement>) => {
            event.stopPropagation();
          }}
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            event.stopPropagation();
            const target = event.target as HTMLInputElement;
            if (toggleIngredients) {
              toggleIngredients(target.checked);
            }
          }}
        />
        <TypographySecondary component="h3" font="sebenta" size={14} className={styles.recipeTitle}>
          {title}
        </TypographySecondary>
      </div>
      <Typography className={styles.recipeIngredientsNumber}>
        {numberOfSelectedShoppableProducts} ingredients
      </Typography>
      <Typography className={styles.recipePrice}>
        {formatAsPounds(totalPriceOfSelectedShoppableProducts)}
      </Typography>
    </div>
  );
};

export default AccordionRecipeTitle;
