import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  MutableRefObject,
} from 'react';
import { useClientOnlyLayoutEffect } from 'hooks/use-client-only-layout-effect';

import classNames from 'classnames';

import usePrevious from 'hooks/use-previous';

import { setBodyNoScroll } from 'utils/scroll';
import { singleResizeWatcher } from 'utils/single-event-watcher';

import { Close } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import CMSMenuPanel from 'components/MegaMenu/CMSMenuPanel';
import MenuLinksList from 'components/MegaMenu/MenuLinksList';
import MenuExperienceFragmentClickHandler from 'components/ExperienceFragment/MenuExperienceFragmentClickHandler';
import ExperienceFragment from 'components/ExperienceFragment';

import {
  getMegaMenuActiveLevel,
  getMegaMenuMaxLength,
  getMegaMenuMenus,
  getExperienceFragmentKeyForActiveMenuItem,
} from 'redux/modules/page/selectors/mega-menu';
import { setMegaMenu } from 'redux/modules/page/actions/set-mega-menu';
import { getIsTaxonomyLoading } from 'redux/modules/taxonomy/selectors';
import { useWtrDispatch, useWtrSelector } from 'redux/hooks';
import { NavigationContextApiRef } from 'components/MegaMenu/NavigationProvider/NavigationProvider';

import styles from './index.scss';

type Props = {
  handleClickToClose: (event: MouseEvent) => void;
  isOpen: boolean;
  navigationRef: MutableRefObject<NavigationContextApiRef>;
  onToggle: () => void;
  scrolled: boolean;
};

type Menu = {
  id: string;
  name: string;
  path: string;
  urlName: string;
  banner?: string;
};

const MegaMenu = ({
  handleClickToClose,
  isOpen = false,
  navigationRef,
  onToggle,
  scrolled = false,
}: Props) => {
  const dispatch = useWtrDispatch();
  const activeLevel = useWtrSelector(getMegaMenuActiveLevel);
  const loading = useWtrSelector(getIsTaxonomyLoading);
  const maxMenus = useWtrSelector(getMegaMenuMaxLength);
  const menus: Menu[] = useWtrSelector(getMegaMenuMenus);
  const experienceFragmentKeyForActiveMenuItem = useWtrSelector(
    getExperienceFragmentKeyForActiveMenuItem,
  );

  const previousLevel = usePrevious(activeLevel);
  const previousOpen = usePrevious(isOpen);
  const scrollContainer = useRef<HTMLElement | null>(null);
  const [keyboardLevel, setKeyboardLevel] = useState<number | null>(null);
  const [hideSubMenu, setHideSubMenu] = useState<boolean>(false);
  const hasBannerForActiveMenuItem = !!experienceFragmentKeyForActiveMenuItem;

  useEffect(() => {
    if (!previousOpen && isOpen) {
      singleResizeWatcher(() => {
        onToggle();
        setBodyNoScroll(false);
      });
    }
    if (previousOpen && !isOpen) setKeyboardLevel(null);
  }, [isOpen, previousOpen, onToggle]);

  useClientOnlyLayoutEffect(() => {
    const { current } = scrollContainer;

    if (current && previousLevel !== activeLevel && isOpen) {
      current.scrollTop = 0;
    }
  });

  const resetMenu = useCallback(
    event => {
      if (activeLevel === 0) {
        handleClickToClose(event);
      } else {
        setHideSubMenu(true);
      }
      setTimeout(() => {
        dispatch(setMegaMenu(0, '10051'));
        setHideSubMenu(false);
      }, 200);
    },
    [activeLevel, handleClickToClose, dispatch],
  );

  return (
    <div
      aria-label="Explore Waitrose"
      className={classNames(styles.nav, {
        [styles.open]: isOpen,
        [styles.closed]: !isOpen,
        [styles.rootActive]: !activeLevel,
        [styles.scrolled]: scrolled,
      })}
      data-testid="megamenu-open"
      id="megamenu"
      role="region"
    >
      {(!loading && activeLevel !== -1 && (
        <nav
          className={classNames(styles.menus, {
            [styles.hideSubMenu]: hideSubMenu,
            [styles.activeLevel]: activeLevel,
          })}
          role="navigation"
          ref={scrollContainer}
        >
          {menus.map((menu, currentLevel) =>
            currentLevel < maxMenus ? (
              <MenuLinksList
                key={menu.id}
                keyboardLevel={keyboardLevel}
                level={currentLevel}
                navigationRef={navigationRef}
                parentId={menus[currentLevel - 1]?.id}
                setKeyboardLevel={setKeyboardLevel}
                handleClickToClose={handleClickToClose}
              />
            ) : null,
          )}
          {activeLevel === 2 && !!hasBannerForActiveMenuItem && (
            <div className={styles.banner}>
              <MenuExperienceFragmentClickHandler
                trackingPrefix="adtech-xf"
                additionalTrackingData={{ xf: experienceFragmentKeyForActiveMenuItem }}
                onClose={handleClickToClose}
              >
                <ExperienceFragment
                  experienceFragmentKey={experienceFragmentKeyForActiveMenuItem}
                  locationName="banner"
                />
              </MenuExperienceFragmentClickHandler>
            </div>
          )}
        </nav>
      )) || <p className={styles.loading}>Loading...</p>}
      {!loading && activeLevel === 0 && <CMSMenuPanel onClose={handleClickToClose} />}
      <button
        className={styles.close}
        data-testid="menu-close"
        id="menu-close"
        onClick={resetMenu}
        type="button"
      >
        <span className="sr-only">Close</span>
        <Close className={styles.icon} size="small" />
      </button>
    </div>
  );
};

MegaMenu.displayName = 'MegaMenu';

export default MegaMenu;
