import React, { useState, useEffect, useCallback, MutableRefObject } from 'react';
import classNames from 'classnames';

import { KEY_DOWN, KEY_ENTER, KEY_UP, KEY_TAB } from 'constants/keys';

import ScreenReaderAnnouncement from 'components/ScreenReaderAnnouncement';
import Highlight from './Highlight';

import styles from './AutoComplete.scss';

export interface Props {
  active: boolean;
  inputRef: MutableRefObject<HTMLInputElement> | null;
  homepage?: boolean;
  inline?: boolean;
  searchTerm: string;
  suggestions: string[];
  onClear: () => void;
  onSelectItem: (suggestion: string) => void;
}

const AutoComplete = ({
  active = false,
  inputRef = null,
  homepage = false,
  inline = false,
  searchTerm = '',
  suggestions = [],
  onClear,
  onSelectItem,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleItemClick = useCallback(
    (suggestion: string) => {
      onSelectItem(suggestion);
    },
    [onSelectItem],
  );

  const handleTermKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.keyCode) {
        case KEY_DOWN: {
          if (selectedIndex === null) {
            setSelectedIndex(0);
            break;
          }

          const newSelectedIndex = selectedIndex + 1;
          if (newSelectedIndex < suggestions.length) {
            setSelectedIndex(newSelectedIndex);
          }
          break;
        }
        case KEY_UP: {
          event.preventDefault();
          const newSelectedIndex = (selectedIndex || 0) - 1;
          if (newSelectedIndex >= 0) {
            setSelectedIndex(newSelectedIndex);
          } else {
            setSelectedIndex(null);
          }
          break;
        }
        case KEY_ENTER: {
          if (selectedIndex !== null) {
            handleItemClick(suggestions[selectedIndex]);
          }
          break;
        }
        case KEY_TAB: {
          onClear();
          setSelectedIndex(null);
          break;
        }
        default: {
          setSelectedIndex(null);
        }
      }
    },
    [handleItemClick, onClear, selectedIndex, suggestions],
  );

  useEffect(() => {
    const ref = inputRef?.current;
    if (ref && ref.addEventListener) {
      ref.addEventListener('keydown', handleTermKeyDown);
    }

    return () => {
      if (ref && ref.removeEventListener) {
        ref.removeEventListener('keydown', handleTermKeyDown);
      }
    };
  }, [handleTermKeyDown, inputRef]);

  if (!active || suggestions.length < 1) return null;

  return (
    <section
      className={classNames(styles.autoComplete, {
        [styles.homepage]: homepage,
        [styles.inline]: inline,
      })}
      data-testid="autocomplete"
    >
      <ul className={styles.suggestions} id="autocomplete-listbox" role="listbox">
        {suggestions.map((suggestion, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <li
            aria-selected={index === selectedIndex}
            className={classNames(styles.suggestion, {
              [styles.selected]: index === selectedIndex,
            })}
            data-testid="suggestion"
            key={suggestion}
            id="autocomplete-suggestion"
            onClick={() => handleItemClick(suggestion)}
            role="option"
          >
            <Highlight match={searchTerm} string={suggestion} />
          </li>
        ))}
      </ul>
      <ScreenReaderAnnouncement message={`${suggestions.length} suggestions available.`} />
    </section>
  );
};

AutoComplete.displayName = 'AutoComplete';

export default AutoComplete;
