import {
  SORT_URL_CATEGORY_RANKING,
  SORT_URL_PURCHASE_FREQUENCY,
} from 'redux/modules/favourites/constants';

export const favouritesSortOptions = [
  {
    children: 'Category',
    value: SORT_URL_CATEGORY_RANKING,
  },
  {
    children: 'Frequently bought',
    value: SORT_URL_PURCHASE_FREQUENCY,
  },
];

export const TOOLTIP_SORT_BY_PREFERENCE = 'wtr_favourites_sort_by_preference_tooltip';
