import { createSelector } from 'reselect';

import { WtrRootState } from 'redux/create-store';

import { OTHERS } from 'constants/categoryIds';

import { getData } from '.';

export type Category = {
  id: string;
  categoryIds: string[];
  name: string;
  banner: string;
  hasDescendants: boolean;
  emptyOfProducts: false;
  hiddenInNav: false;
  pageTitle: string;
  categories: {
    [key: string]: Category;
  };
};

type Data = {
  [key: string]: Category;
};

const returnId = (_state: WtrRootState, id: string) => id;

const othersCategory = { id: OTHERS, name: 'Others' };

export const getCategoryById = createSelector([getData, returnId], (data = {}, id) => {
  if (OTHERS === id) {
    return othersCategory;
  }
  return { ...data[id], ...(!!data[id] && { hasDescendants: !!data[id]?.categoryIds?.length }) };
});

export const getMenuCategoryById = createSelector(
  [getCategoryById, getData],
  (category: Category, data: Data) => {
    const childCategoryIds = category.categoryIds ?? [];

    // Filter out child categories that are empty of products or hidden
    const categoryIds = childCategoryIds.filter(
      (id: string) => data[id] && !data[id]?.emptyOfProducts && !data[id]?.hiddenInNav,
    );

    // Decorate with child category objects
    const categories = categoryIds
      .map(id => data[id])
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: {
            ...item,
            hasDescendants: !!item.categoryIds?.length,
          },
        }),
        {},
      );
    return {
      ...category,
      categoryIds,
      categories,
    };
  },
);
