import { DEFAULT_BUTTON_VALUE } from 'constants/checkout/errorMessages';
import urls from 'constants/urls';
import { openModal } from 'redux/modules/common-modal/actions';
import { preserveStubsUrl } from 'utils/general';
import locator from 'utils/locator';

export default errorResponse => dispatch => {
  const status = errorResponse?.status;
  const code = errorResponse?.response?.body?.code;

  if (status === 409 && code === 'ORDER_ORCHESTRATION_041') {
    const onCloseCallback = () => {
      locator.href = preserveStubsUrl(urls.trolleyPage);
    };

    dispatch(
      openModal({
        id: 'checkout-error-trolley-hard-conflict',
        severity: 'conflict',
        buttons: [
          {
            buttonText: 'View trolley',
            buttonValue: DEFAULT_BUTTON_VALUE,
            primary: true,
          },
        ],
        cannotClose: true,
        messageText: ['Please address the issues displayed in your trolley before you continue.'],
        titleText: 'There are problems with your trolley',
        webview: {
          id: 'checkout-error-15',
          ctas: [urls.trolleyPage],
        },
        useIngredientsModal: false,
        onCloseCallback,
      }),
    );

    return true;
  }

  return false;
};
