import React, { memo } from 'react';

import SpecialistShopsLinks from 'components/SpecialistShopsLinks';
import styles from 'components/SiteHeader/TopNav/TopNav.scss';
import CustomerServiceLinks from 'components/CustomerServiceLinks';

type Props = {
  staticRender: boolean;
};

const TopNav = memo(({ staticRender = false }: Props) => (
  <nav
    className={`visible-lg-block visible-xl-block ${styles.wrapper}`}
    role="navigation"
    aria-label="Top Navigation Bar"
  >
    <div className="container-fluid">
      <div className={`row ${styles.row}`}>
        <SpecialistShopsLinks />
        {!staticRender ? <CustomerServiceLinks /> : null}
      </div>
    </div>
  </nav>
));

export default TopNav;
