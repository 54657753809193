import { createSelector } from 'reselect';
import historyUtil from 'utils/history';

import urls from 'constants/urls';

const getInterstitials = state => state.interstitials;
const getInterstitialStepId = (state, stepId) => stepId;

export const isInterstitials = () =>
  historyUtil.getLocation().pathname.includes(urls.interstitials);

export const isInterstitialLoading = createSelector(
  [getInterstitials],
  (interstitials = {}) => interstitials.loading || false,
);

export const getInterstitialsContentEmpty = createSelector(
  [getInterstitials],
  (interstitials = {}) => !!interstitials.empty,
);

export const getInterstitialsSteps = createSelector(
  [getInterstitials, (_state, { withoutBeforeYouGo } = {}) => withoutBeforeYouGo],
  (interstitials = {}, withoutBeforeYouGo = false) => {
    const steps = interstitials.steps || [];
    return withoutBeforeYouGo ? steps.filter(({ label }) => label !== 'Before you go') : steps;
  },
);

export const getInterstitialsFilteredSteps = createSelector([getInterstitialsSteps], steps =>
  steps.filter(step => step.valid),
);

const getCurrentInterstitialsStepIndex = createSelector([getInterstitialsSteps], steps =>
  steps.findIndex(step => step.current),
);

export const getCurrentInterstitialsFilteredStepIndex = createSelector(
  [getInterstitialsFilteredSteps],
  steps => steps.findIndex(step => step.current),
);

export const getCuratedFilteredSteps = createSelector(
  [getInterstitialsFilteredSteps, getCurrentInterstitialsFilteredStepIndex],
  (steps, currentIndex) => {
    const newSteps = steps.map((step, index) => {
      return { ...step, isCompleted: index < currentIndex };
    });
    if (newSteps.length) {
      newSteps.push({
        isCompleted: false,
        current: false,
        id: 'checkout',
        valid: true,
        visited: false,
        label: 'Checkout',
      });
    }

    return newSteps;
  },
);

export const getInterstialsPageNumber = createSelector(
  [getCurrentInterstitialsStepIndex],
  (index = 0) => index + 1,
);

export const getInterstitialsStepById = createSelector(
  [getInterstitialsSteps, getInterstitialStepId],
  (steps, stepId) => steps.find(({ id }) => id === stepId),
);

export const getNextInterstitialsStep = createSelector(
  [getInterstitialsSteps, getCurrentInterstitialsStepIndex],
  (steps, currentIndex) =>
    steps.slice(currentIndex + 1).filter(step => step.visited || step.valid)[0],
);

export const isLastInterstitial = createSelector(
  [getNextInterstitialsStep],
  nextStep => !!nextStep,
);

export const getInterstitialsLoaded = createSelector(
  [getInterstitials],
  (interstitials = {}) => interstitials.loaded || false,
);

export const getInterstitialsValid = createSelector(
  [getInterstitials],
  (interstitials = {}) => interstitials.valid || false,
);

export const getNextInterstitialsPath = createSelector([getNextInterstitialsStep], nextStep =>
  nextStep ? `${urls.interstitials}/${nextStep.id}` : undefined,
);

export const getIsMissedOffersInterstitial = createSelector(
  [getCurrentInterstitialsFilteredStepIndex, getInterstitialsSteps],
  (currentStepId, steps) => steps[currentStepId]?.label === 'Missed offers',
);
