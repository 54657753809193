import React from 'react';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import classNames from 'classnames';
import styles from './index.scss';
import { useClientSideMediaQuery } from '../../../../hooks/use-client-side-media-query';
import { BREAKPOINTS } from '../../../../constants/grid';

interface HowItWorksModal {
  isOpen: boolean;
  closeModal: () => void;
}

const HowItWorksModal = ({ isOpen, closeModal }: HowItWorksModal) => {
  const isMobile = useClientSideMediaQuery({ maxWidth: BREAKPOINTS.sm });

  return (
    <Modal
      titleText="How it works"
      isOpen={isOpen}
      handleModalClose={closeModal}
      contentClassName={styles.modalContent}
      fullScreen={isMobile}
    >
      <Typography component="h2" styleAs="paragraphHeading" className={styles.stepsHeading}>
        Steps
      </Typography>
      <div className={styles.step}>
        <div className={styles.iconContainer}>
          <div className={classNames(styles.chefIcon, styles.icon)} />
        </div>
        <div>
          <Typography component="h3" styleAs="paragraphHeading">
            1. Based on your tastes
          </Typography>
          <Typography component="p" styleAs="paragraph">
            Your dinner meal plan is based on your chosen preferences.
          </Typography>
        </div>
      </div>
      <div className={styles.step}>
        <div className={styles.iconContainer}>
          <div className={classNames(styles.entertainingIcon, styles.icon)} />
        </div>
        <div>
          <Typography component="h3" styleAs="paragraphHeading">
            2. Flexible
          </Typography>
          <Typography component="p" styleAs="paragraph">
            Remove or swap any recipe from your meal plan.
          </Typography>
        </div>
      </div>
      <div className={styles.step}>
        <div className={styles.iconContainer}>
          <div className={classNames(styles.trolleyInactiveIcon, styles.icon)} />
        </div>
        <div>
          <Typography component="h3" styleAs="paragraphHeading">
            3. Fast
          </Typography>
          <Typography component="p" styleAs="paragraph">
            Add all your meal plan ingredients to your trolley in one click.
          </Typography>
        </div>
      </div>

      <hr className={styles.divider} />
      <Typography component="h2" styleAs="paragraphHeading">
        Information
      </Typography>

      <Typography
        component="p"
        className={classNames(styles.informationIcon, styles.swapIcon)}
        styleAs="paragraph"
      >
        Use the swap icon to change a recipe.
      </Typography>

      <Typography
        component="p"
        className={classNames(styles.informationIcon, styles.removeIcon)}
        styleAs="paragraph"
      >
        Remove any recipe by clicking the (X) icon.
      </Typography>

      <Typography
        component="p"
        className={classNames(styles.informationIcon, styles.settingsIcon)}
        styleAs="paragraph"
      >
        Edit preferences to filter recipes.
      </Typography>
    </Modal>
  );
};

export default HowItWorksModal;
