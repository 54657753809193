import React from 'react';
import { Helmet } from 'react-helmet-async';
import { getMetaData, getRecipeCardsGrid } from 'redux/modules/cms-hybrid-recipes-page/selectors';
import { useWtrSelector } from 'redux/hooks';
import env from 'env/env';
import { useLocation } from 'react-router-dom';
import Canonical from 'components/Canonical';
import PlannerGrid from './MealPlannerGrid';
import plannerStyles from './index.scss';

const PrebuiltPlanner = () => {
  const grid = useWtrSelector(getRecipeCardsGrid);
  const meta = useWtrSelector(getMetaData);
  const { pathname } = useLocation();

  const canonicalHref = `https://${env.prodHost}${pathname}`;

  return (
    <>
      <Canonical href={canonicalHref} absolute={false} />
      <Helmet title={meta.title}>
        <meta property="og:title" content={meta.title} />
        <meta name="description" content={meta.description} />
        <meta name="robots" content={meta.turnOffIndexing ? 'noindex' : 'index'} />
        <meta name="keywords" content={meta.keywords as string} />
      </Helmet>
      <div className={plannerStyles.content}>
        <PlannerGrid title={grid ? grid.gridTitle : ''} subtitle={grid?.gridSubtitle} />
      </div>
    </>
  );
};

export default PrebuiltPlanner;
