import { createSelector } from '@reduxjs/toolkit';
import { Recipe } from 'api/definitions/recipes/index.d';
import { convertDisplayPriceToNumber } from '../../../../utils/parse-display-price';
import { RecipeStateItem, RecipeStateItemError, RecipesRoot, RecipesState } from '../reducers';
import { IngredientGroups, ShoppableItem } from '../index.d';

export interface GetRecipeType extends RecipeStateItem {
  metaDescription: NonNullable<RecipeStateItem['metaDescription']>;
}

export const getRecipe = ({ recipes }: RecipesRoot): GetRecipeType => {
  const recipe: RecipeStateItem | RecipeStateItemError = recipes[recipes.id] || {};
  return {
    ...(recipes as unknown as RecipeStateItem),
    cookingTime: recipe.cookingTime,
    isShoppable: (!(recipe as unknown as RecipeStateItemError).pimsError &&
      recipes.isShoppable) as RecipeStateItem['isShoppable'],
    metaDescription: recipe.metaDescription || recipe.description?.plainText || '',
    products: recipe.products,
    rating: recipe.rating,
  };
};

export const getRecipeById =
  (id: Recipe['id']) =>
  ({ recipes }: RecipesRoot) => {
    return recipes?.[id];
  };

const getRecipeProducts = ({ recipes }: RecipesRoot) => recipes[recipes.id]?.products;

export const getLineNumbers = createSelector(
  [getRecipeProducts],
  recipeProducts =>
    recipeProducts?.map(
      /* @ts-expect-error TODO */
      ingredient => ingredient.products[0]?.lineNumber,
    ) || [],
);

export const getShoppableProducts = ({ recipes }: RecipesRoot, recipeId?: Recipe['id']) => {
  const recipe = recipes[recipeId || recipes.id];

  return recipe ? recipe.shoppableProducts : null;
};

const selectRecipes = (state: RecipesRoot) => state.recipes;

// @ts-expect-error: createSelector known issue, where the return function is typed as receiving a single arg
export const getMultipleRecipesShoppableProducts = createSelector(
  selectRecipes,
  (_, recipeIds) => recipeIds,
  (
    recipes: RecipesState,
    recipeIds: (Recipe['id'] | undefined)[],
  ): Record<string, IngredientGroups[]> => {
    return recipeIds.reduce<Record<Recipe['id'], IngredientGroups[]>>(
      (shoppableProducts, recipeId) => {
        const recipe = recipeId && recipes[recipeId as unknown as Recipe['id']];
        if (recipe) shoppableProducts[recipeId] = recipe.shoppableProducts; // eslint-disable-line no-param-reassign
        return shoppableProducts;
      },
      {},
    );
  },
);

export const getNumberOfSelectedShoppableProducts = (
  { recipes }: RecipesRoot,
  recipeId?: Recipe['id'],
) => {
  const recipe = recipes[recipeId || recipes.id] || {};
  const { shoppableProducts } = recipe;

  return shoppableProducts?.length
    ? shoppableProducts.reduce((total, { ingredients }) => {
        const { storeCupboard = [], nonStoreCupboard = [] } = ingredients;

        const ingredientFilter = (ingredient: ShoppableItem) =>
          !!ingredient?.lineNumber && ingredient.amountSelected > 0;

        const filteredStoreCupboard = storeCupboard.filter(ingredientFilter);
        const filteredNonStoreCupboard = nonStoreCupboard?.filter(ingredientFilter);

        return total + filteredStoreCupboard.length + filteredNonStoreCupboard.length;
      }, 0)
    : 0;
};

export const getNumberOfShoppableProducts = ({ recipes }: RecipesRoot, recipeId?: Recipe['id']) => {
  const recipe = recipes[recipeId || recipes.id] || {};
  const { shoppableProducts } = recipe;

  return shoppableProducts?.length > 0
    ? shoppableProducts.reduce((total, { ingredients }) => {
        const { storeCupboard, nonStoreCupboard } = ingredients;
        return total + storeCupboard.length + nonStoreCupboard.length;
      }, 0)
    : 0;
};

export const getNumberOfSelectedShoppableProductsForMultipleRecipes = (
  state: RecipesRoot,
  recipeIds: (Recipe['id'] | undefined)[],
) =>
  recipeIds.reduce(
    (total, recipeId) => total + getNumberOfSelectedShoppableProducts(state, recipeId),
    0,
  );

export const getNumberOfShoppableProductsForMultipleRecipes = (
  state: RecipesRoot,
  recipeIds: (Recipe['id'] | undefined)[],
) =>
  recipeIds.reduce((total, recipeId) => total + getNumberOfShoppableProducts(state, recipeId), 0);

export const getTotalPriceOfShoppableProducts = (
  { recipes }: RecipesRoot,
  recipeId?: Recipe['id'],
  selectedOnly = false,
) => {
  const recipe = recipes[recipeId || recipes.id] || {};
  const { shoppableProducts } = recipe;

  return shoppableProducts?.length
    ? shoppableProducts.reduce((total, { ingredients }) => {
        const { storeCupboard, nonStoreCupboard } = ingredients;

        const filteredStoreCupboard =
          storeCupboard.filter(ingredient => !!ingredient?.lineNumber) ?? [];
        const filteredNonStoreCupboard =
          nonStoreCupboard?.filter(ingredient => !!ingredient?.lineNumber) ?? [];

        return (
          total +
          filteredStoreCupboard.reduce((storeTotal, ingredient) => {
            const numberToAdd =
              !selectedOnly || ingredient.amountSelected > 0
                ? convertDisplayPriceToNumber(ingredient?.totalPrice) || 0
                : 0;
            return storeTotal + numberToAdd;
          }, 0) +
          filteredNonStoreCupboard.reduce((nonStoreTotal, ingredient) => {
            const numberToAdd =
              !selectedOnly || ingredient.amountSelected > 0
                ? convertDisplayPriceToNumber(ingredient?.totalPrice) || 0
                : 0;
            return nonStoreTotal + numberToAdd;
          }, 0)
        );
      }, 0)
    : 0;
};

export const getRecipesTotalPriceOfSelectedShoppableProducts = (
  state: RecipesRoot,
  recipeIds: (Recipe['id'] | undefined)[],
) =>
  recipeIds.reduce(
    (total, recipeId) => total + getTotalPriceOfShoppableProducts(state, recipeId, true),
    0,
  );

export const getNumberOfRecipesWithShoppableProducts = (
  state: RecipesRoot,
  recipeIds: (Recipe['id'] | undefined)[],
) =>
  recipeIds.reduce(
    (total, recipeId) => (getNumberOfShoppableProducts(state, recipeId) > 0 ? total + 1 : total),
    0,
  );

export const getNumberOfRecipesWithSelectedShoppableProducts = (
  state: RecipesRoot,
  recipeIds: (Recipe['id'] | undefined)[],
) =>
  recipeIds.reduce(
    (total, recipeId) =>
      getNumberOfSelectedShoppableProducts(state, recipeId) > 0 ? total + 1 : total,
    0,
  );
