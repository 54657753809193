import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { useWtrSelector } from 'redux/hooks';
import { Alert } from '@johnlewispartnership/wtr-ingredients/ingredients/Alert/Alert';
import { Button } from '@johnlewispartnership/wtr-ingredients/ingredients/Button/Button';
import { Radio } from '@johnlewispartnership/wtr-ingredients/ingredients/Radio/Radio';
import { LabeledControl } from '@johnlewispartnership/wtr-ingredients/ingredients/LabeledControl/LabeledControl';
import { getMealPlanner } from 'redux/modules/meal-planner/selectors';
import { Filter } from 'services/recipes-landing-service';
import classnames from 'classnames';
import step1Styles from './Step-1.scss';
import styles from './ServesModal.scss';

interface ServesModal {
  isOpen: boolean;
  closeModal: () => void;
}

const ServesModal = ({ isOpen, closeModal }: ServesModal) => {
  const { filters } = useWtrSelector(getMealPlanner);
  const servingSizes: Filter['values'] =
    filters.find(filter => filter.title === 'Serving Size')?.values || [];
  const [selectedServingSizeName, setSelectedServingSizeName] = useState('');
  const [showError, setShowError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleApplyClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!selectedServingSizeName) {
      event.preventDefault();
      setShowError(true);
    } else {
      searchParams.set('Serving Size', selectedServingSizeName);
      setSearchParams(searchParams);
      navigate(0);
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      handleModalClose={closeModal}
      contentClassName={classnames(styles.modalContent, 'no-print')}
      cannotClose
      standardHeadingEnabled={false}
    >
      <Typography component="h1" styleAs="screenHeadingSmall" className={styles.title}>
        How many people are you cooking for?
      </Typography>

      {showError && (
        <div className={styles.alertWrapper}>
          <Alert
            type="error"
            title="No option selected"
            aria-live="polite"
            role="alert"
            data-testid="alert"
          >
            Please make a selection before you continue
          </Alert>
        </div>
      )}

      {servingSizes.map(({ name }) => (
        <LabeledControl
          className={step1Styles.label}
          name="servingSize"
          key={name}
          control={<Radio checked={selectedServingSizeName === name} />}
          label={name}
          onChange={() => {
            setSelectedServingSizeName(name);
            setShowError(false);
          }}
        />
      ))}

      <Button theme="primary" onClick={handleApplyClick} className={styles.applyButton}>
        Apply
      </Button>
    </Modal>
  );
};

export default ServesModal;
