import React, { useCallback, useRef, useState, useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import { useWtrSelector } from 'redux/hooks';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FORMAT, format } from 'utils/date';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import {
  CalendarBooked as CalendarBookedIcon,
  Calendar as CalendarIcon,
  ChevronUp,
  ChevronDown,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { Spinner } from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';
import { useClientSideMediaQuery } from 'hooks/use-client-side-media-query';
import { BREAKPOINTS } from 'constants/grid';

import { KEY_ENTER } from 'constants/keys';
import urls from 'constants/urls';

import {
  customerSlotSelector,
  hasBookedSlot,
  isBookedSlotACollectionSlot,
  isCustomerSlotLoading as isCustomerSlotLoadingSelector,
} from 'redux/modules/customer-slot/selectors';
import { getCustomerSlotBranchAddress } from 'redux/modules/customer-slot/selectors/get-customer-slot-branch-address';
import { getCustomerDeliveryAddress } from 'redux/modules/customer-slot/selectors/get-customer-delivery-address';
import { isUserLoggedIn as isUserLoggedInSelector } from 'redux/modules/sessions/selectors';
import { getEditSlotUrl } from 'redux/modules/slot-booking/selectors/get-edit-slot-url';
import amendingOrder from 'redux/modules/trolley/selectors/amending-order';
import isSlotChangeable from 'redux/modules/trolley/selectors/slot-changeable';

import { dataLayer } from 'analytics/data-layer';
import { formatSlotTime } from 'utils/format-slot-time';

import { LinkAsButton } from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton';
import SlotButtonText from 'components/SiteHeader/SlotButton/SlotButtonText/SlotButtonText';
import { LoadableServiceSelection } from 'components/App/loadableComponents';

import SlotButtonAlert from './Alert';
import styles from './SlotButton.scss';

const SlotButton = ({ isMobile, testId, textOnly, shortTime }) => {
  const [hidden, setHidden] = useState(true);
  const isCollection = useWtrSelector(isBookedSlotACollectionSlot);
  const { line1, line2, name, postalCode, region, town } = useWtrSelector(state =>
    isCollection ? getCustomerSlotBranchAddress(state) : getCustomerDeliveryAddress(state),
  );
  const amending = useWtrSelector(amendingOrder);
  const changeSlotUrl = useWtrSelector(getEditSlotUrl);
  const isSlotBooked = useWtrSelector(hasBookedSlot);
  const slotChangeable = useWtrSelector(isSlotChangeable);
  const { slotDate, slotEndTime, slotStartTime } = useWtrSelector(customerSlotSelector);
  const blurButton = () => setHidden(true);
  const focusButton = () => setHidden(false);
  const isCustomerSlotLoading = useWtrSelector(isCustomerSlotLoadingSelector);
  const isUserLoggedIn = useWtrSelector(isUserLoggedInSelector);
  const spinnerTheme = isMobile ? 'dark' : 'light';
  const isDesktop = useClientSideMediaQuery({ minWidth: BREAKPOINTS.lg });

  const toggleVisibility = () => {
    if (hidden) {
      dataLayer.push({
        event: 'slot_details_button_click',
        eventAction: 'ClickSlotDetails',
        eventCategory: 'On-Page Interactions',
        eventLabel: `Header`,
        eventValue: undefined,
      });
    }

    setHidden(!hidden);
  };

  const handleChangeSlot = () => {
    setHidden(true);

    dataLayer.push({
      event: 'change_slot_button_click',
      eventAction: 'ClickChangeSlot',
      eventCategory: 'On-Page Interactions',
      eventLabel: `ViewSlotBooked`,
      eventValue: undefined,
    });
  };

  const handleClickOutside = () => {
    if (!hidden) {
      setHidden(true);
    }
  };

  const handleKeyDown = event => {
    const { keyCode } = event;

    if (keyCode === KEY_ENTER) {
      handleChangeSlot();
    }
  };

  const handleLinkClick = () => {
    dataLayer.push({
      event: 'click_book_slot',
    });
  };

  const componentPreloadRef = useRef(false);

  const preloadSlotPage = useCallback(() => {
    if (componentPreloadRef.current) {
      return;
    }

    componentPreloadRef.current = true;
    LoadableServiceSelection.preload();
  }, []);

  const longDate = format(slotDate, FORMAT.LONG_2DAY_MONTH);

  const longTime = `${formatSlotTime(slotStartTime)}-${formatSlotTime(slotEndTime)}`;

  const slotButtonText =
    isUserLoggedIn && isCustomerSlotLoading
      ? ''
      : SlotButtonText({
          isSlotBooked,
          slotDetails: { slotDate, slotEndTime, slotStartTime },
          shortTime,
        });

  const slotButtonLabelId = `slot-button-label-${testId}`;

  const endIcon = useMemo(() => {
    if (!isDesktop) return undefined;
    if (hidden) return <ChevronDown size="small" />;
    return <ChevronUp size="small" />;
  }, [isDesktop, hidden]);

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <div className={styles.wrapper} data-testid={`slot-button-${testId}`}>
        <div className={classNames({ [styles.textOnly]: textOnly })}>
          {isSlotBooked ? (
            <Button
              aria-hidden
              data-testid={`slot-details-button-${testId}`}
              className={styles.slotButton}
              startIcon={!textOnly ? <CalendarBookedIcon /> : undefined}
              endIcon={endIcon}
              label={slotButtonText}
              onClick={toggleVisibility}
              tabIndex="-1"
              theme={!textOnly ? 'primary' : 'primaryWhite'}
              width="full"
            />
          ) : (
            <LinkAsButton
              component={Link}
              data-testid={`book-slot-button-${testId}`}
              startIcon={!textOnly ? <CalendarIcon /> : undefined}
              label={slotButtonText}
              onClick={handleLinkClick}
              onMouseEnter={preloadSlotPage}
              theme={!textOnly ? 'primary' : 'secondary'}
              to={urls.serviceSelection}
              type="button"
              width="full"
            >
              <Spinner isActive={isCustomerSlotLoading} size="small" theme={spinnerTheme} />
            </LinkAsButton>
          )}
        </div>
        {isSlotBooked && (
          <div
            className={classNames(styles.details, { 'sr-only': hidden })}
            data-testid={`slot-details-${testId}`}
          >
            <div className={styles.header}>
              <header aria-hidden="true">
                Your {isCollection ? 'collection' : 'delivery'} slot
              </header>
            </div>
            <div className={styles.slotDateTime}>
              <span className="sr-only">Booked for:</span>
              <span>{longDate}</span>
              <span className={styles.slotTime}>{longTime}</span>
            </div>
            <div
              className={styles.deliveryDetails}
              data-cs-mask
              data-testid={`slot-address-details-${testId}`}
            >
              <span className={styles.title}>
                {isCollection ? 'Collect from:' : 'Delivery to:'}
              </span>
              {isCollection && name && <span className={styles.address}>{name}</span>}
              <span className={styles.address}>{line1}</span>
              {line2 && <span className={styles.address}>{line2}</span>}
              <span className={styles.address}>{town}</span>
              {region && <span className={styles.address}>{region}</span>}
              <span className={styles.address}>{postalCode}</span>
            </div>
            {amending && !slotChangeable ? (
              <div className={styles.alert}>
                <SlotButtonAlert />
              </div>
            ) : (
              <div className={styles.button}>
                <span className="sr-only" htmlFor="changeSlotButton" id={slotButtonLabelId}>
                  {isCollection ? 'Collection' : 'Delivery'} slot booked for {longDate} {longTime}.{' '}
                  {isCollection ? 'Collect from' : 'Delivery to'} {line1},
                  {line2 ? ` ${line2},` : ''} {town},{region ? ` ${region},` : ''} {postalCode}.
                  Change slot.
                </span>
                <LinkAsButton
                  aria-describedby={slotButtonLabelId}
                  aria-label="Change slot"
                  data-testid={`change-slot-button-${testId}`}
                  component={Link}
                  label="Change slot"
                  onBlur={blurButton}
                  onClick={handleChangeSlot}
                  onFocus={focusButton}
                  onKeyDown={handleKeyDown}
                  theme="primary"
                  to={changeSlotUrl}
                  type="button"
                  width="full"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

SlotButton.propTypes = {
  isMobile: bool,
  textOnly: bool,
  shortTime: bool,
  testId: string,
};

SlotButton.defaultProps = {
  isMobile: false,
  textOnly: false,
  shortTime: false,
  testId: 'desktop',
};

export default SlotButton;
