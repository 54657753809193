import isEqual from 'lodash/isEqual';
import { createSelector, lruMemoize } from 'reselect';
import { getOrderedEntitiesByPromotionId, getPromotions } from 'redux/modules/promotions/selectors';
import {
  getIsMealDeal,
  getPromotions as getPromotionEntities,
} from 'redux/modules/entities/selectors/promotions';
import { getProducts as getProductEntities } from 'redux/modules/entities/selectors/products';
import { getTrolley } from 'redux/modules/trolley/selectors/get-items';

export const getMissedOffers = ({ missedOffers } = {}) => missedOffers;

export const getMissedOffersItems = createSelector([getMissedOffers], ({ items } = {}) => items);

export const filterProductsForHfss = products =>
  products?.filter(product => !product?.hfss?.status);

export const productsByPromoId = (products, filterHfss) =>
  Object.values(filterHfss ? filterProductsForHfss(products) : products).map(
    filteredProduct => filteredProduct.lineNumber,
  );

/* selector that returns an array of offers with all the products lineNumbers associated (filtered by hfss)
  [
    offerId: '999',
    items: ['1', '2']
  ]
*/
export const getMissedOffersItemsWithoutHfssAndWMX = createSelector(
  [getProductEntities, getPromotionEntities, getPromotions, getMissedOffers, getTrolley],
  (productEntities, promotionEntities, promotions, { items: offers } = {}, trolley) => {
    const state = {
      entities: { products: productEntities, promotions: promotionEntities },
      promotions,
      trolley,
    };

    const missedOffers = [];
    offers.forEach(offer => {
      const isMealDeal = getIsMealDeal(state, offer);
      const isWMX = promotionEntities[offer]?.promotionTypeCode === 'WMX';

      if (!isMealDeal && !isWMX) {
        missedOffers.push({
          offerId: offer,
          items: productsByPromoId(getOrderedEntitiesByPromotionId(state, offer), true),
        });
      }
    });

    return missedOffers;
  },
  {
    memoize: lruMemoize,
    memoizeOptions: {
      maxSize: 2,
      resultEqualityCheck: isEqual,
    },
  },
);

/* selector to return number of products displayed to user on Missed Offers page
  If a product have 2 promotions it will count duplications */
export const getMissedOffersItemsCountWithoutHfss = createSelector(
  [getMissedOffersItemsWithoutHfssAndWMX],
  offers =>
    offers.reduce((previousValue, currentValue) => previousValue + currentValue.items?.length, 0),
);
