import React from 'react';
import PropTypes from 'prop-types';

import HeaderLogo from 'components/HeaderLogo';
import { isEmbeddedInWebView } from 'utils/webview';

const SiteHeaderMinimal = ({ staticRender }) => {
  const noLogoLink = isEmbeddedInWebView();

  return (
    <header>
      <HeaderLogo noLink={noLogoLink} minimal staticRender={staticRender} />
    </header>
  );
};

SiteHeaderMinimal.propTypes = {
  staticRender: PropTypes.bool,
};

SiteHeaderMinimal.defaultProps = {
  staticRender: false,
};

export default SiteHeaderMinimal;
