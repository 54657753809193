import { ModalCloseOptions } from 'constants/modalCloseOptions';
import { slotExpiredModal } from 'constants/modals';
import urls from 'constants/urls';
import { openModal } from 'redux/modules/common-modal/actions';
import { preserveStubsUrl } from 'utils/general';
import locator from 'utils/locator';

const openSlotExpiredModal = () =>
  openModal({
    ...slotExpiredModal,
    onCloseCallback: option => {
      if (option === ModalCloseOptions.OK) {
        locator.href = preserveStubsUrl(urls.serviceSelection);
      }
    },
  });

export default openSlotExpiredModal;
