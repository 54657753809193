import React, { memo, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { showContactAddressNotPresentNotification as getShowContactAddressNotPresentNotification } from 'redux/modules/address/selectors/get-address-selectors';

import { pushMenuClick } from 'utils/gtm';

import urls from 'constants/urls';

import { dataLayer } from 'analytics/data-layer';
import { getPathName } from 'redux/modules/slot-booking/selectors/get-fulfilment-type';

import AnchorLink from 'components/AnchorLink';
import {
  ChevronDown,
  ChevronUp,
  DocumentAdd,
  MyWaitrose,
  User,
  Warning,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import SignOutButton from 'components/AuthenticationAction/SignOut/Button';
import {
  LoadableListsPage,
  LoadableMyDetailsPage,
  LoadableMyWaitroseHubHomepage,
} from 'components/App/loadableComponents';

import { useOnClickOutside } from 'hooks';

import styles from './MyAccount.scss';

export const myAccountLabels = {
  myWaitroseLoyalty: 'myWaitrose loyalty',
  lists: 'Lists',
  myDetails: 'My details',
  myOrders: 'My orders',
  favourites: 'Favourites',
  signOut: 'Sign out',
};

const logAnalytics = (button, showContactAddressNotPresentNotification) => {
  const getDataLayerCAText = notificationPresent => {
    if (notificationPresent) {
      switch (button) {
        case 'Header':
          return 'YES: Red Badge';
        case myAccountLabels.myDetails:
          return 'YES: Red Exclamation';
        default:
          return undefined;
      }
    }
    return undefined;
  };

  dataLayer.push({
    event: 'account_menu_click',
    my_account_menu: {
      button,
    },
    prompt: {
      contact_address: getDataLayerCAText(showContactAddressNotPresentNotification),
    },
    temporary: true,
  });
};

const MyAccount = memo(() => {
  const currentPageIsGroceriesHomePage = getPathName() === urls.groceriesHome;
  const showContactAddressNotPresentNotification = useSelector(
    getShowContactAddressNotPresentNotification,
  );
  const [hidden, setHidden] = useState(true);
  const insideRef = useRef();
  const componentPreloadRef = useRef({
    myDetailsPage: false,
    listsPage: false,
    myWaitroseHubHomepage: false,
  });

  const toggleVisibility = useCallback(() => {
    setHidden(!hidden);
  }, [hidden]);

  const handleButtonClick = () => {
    if (hidden) {
      logAnalytics('Header', showContactAddressNotPresentNotification);
    }
    toggleVisibility();
  };

  useOnClickOutside(insideRef, () => {
    if (!hidden) {
      setHidden(true);
    }
  });

  const handleBlur = useCallback(() => {
    if (!hidden) {
      toggleVisibility();
    }
  }, [hidden, toggleVisibility]);

  const handleFocus = useCallback(() => {
    if (hidden) {
      toggleVisibility(false);
    }
  }, [hidden, toggleVisibility]);

  const handleLinkClick = useCallback(
    event => {
      const button = event.target.textContent;

      toggleVisibility();
      pushMenuClick(`My Account: ${button}`);
      logAnalytics(button, showContactAddressNotPresentNotification);
    },
    [showContactAddressNotPresentNotification, toggleVisibility],
  );

  useEffect(() => {
    if (showContactAddressNotPresentNotification && currentPageIsGroceriesHomePage) {
      dataLayer.push({
        event: 'contact_address_red_badge_shown',
        temporary: true,
      });
    }
  }, [currentPageIsGroceriesHomePage, showContactAddressNotPresentNotification]);

  const preloadMyDetails = useCallback(() => {
    if (componentPreloadRef.current.myDetailsPage) {
      return;
    }

    componentPreloadRef.current.myDetailsPage = true;
    LoadableMyDetailsPage.preload();
  }, []);

  const preloadLists = useCallback(() => {
    if (componentPreloadRef.current.listsPage) {
      return;
    }

    componentPreloadRef.current.listsPage = true;
    LoadableListsPage.preload();
  }, []);

  const preloadMyWaitrose = useCallback(() => {
    if (componentPreloadRef.current.myWaitroseHubHomepage) {
      return;
    }

    componentPreloadRef.current.myWaitroseHubHomepage = true;
    LoadableMyWaitroseHubHomepage.preload();
  }, []);

  const endIcon = useMemo(() => {
    if (hidden) return <ChevronDown className={styles.chevronIcon} size="xsmall" />;
    return <ChevronUp className={styles.chevronIcon} size="xsmall" />;
  }, [hidden]);

  return (
    <nav
      aria-labelledby="myAccountAuthenticated"
      ref={insideRef}
      className={styles.accountActionWrapper}
      id="authenticated"
      data-testid="my-account-menu"
    >
      <button
        aria-controls="account-actions"
        aria-expanded={!hidden}
        className={classNames(styles.button)}
        data-testid="my-account-button"
        id="account-details"
        onClick={handleButtonClick}
        tabIndex="-1"
        type="button"
      >
        <span
          className={classNames(styles.iconWrapper, {
            [styles.CANotPresent]: showContactAddressNotPresentNotification,
          })}
          data-testid="my-account-icon-wrapper"
        >
          <User className={styles.buttonIcon} />
        </span>
        <span id="myAccountAuthenticated">My account</span>
        {endIcon}
      </button>
      <div className={classNames(styles.accountPopup, { 'sr-only': hidden })}>
        <ul className={styles.accountActions} id="account-actions">
          <li>
            <AnchorLink
              data-testid="my-details-link"
              id="my-details"
              onBlur={handleBlur}
              onClick={handleLinkClick}
              onFocus={handleFocus}
              onMouseEnter={preloadMyDetails}
              to={urls.myDetailsPage}
            >
              <User className={styles.accountMenuIcon} size="medium" />
              {myAccountLabels.myDetails}
              {showContactAddressNotPresentNotification && (
                <Warning data-testid="warningIcon" className={styles.iconNoCAWarning} />
              )}
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              data-testid="lists-link"
              onBlur={handleBlur}
              onClick={handleLinkClick}
              onFocus={handleFocus}
              onMouseEnter={preloadLists}
              to={urls.lists}
            >
              <DocumentAdd className={styles.accountMenuIcon} size="medium" />
              {myAccountLabels.lists}
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              data-testid="my-waitrose-link"
              onBlur={handleBlur}
              onClick={handleLinkClick}
              onFocus={handleFocus}
              onMouseEnter={preloadMyWaitrose}
              to={urls.myWaitrosePage}
            >
              <MyWaitrose className={styles.accountMenuIcon} size="medium" />
              {myAccountLabels.myWaitroseLoyalty}
            </AnchorLink>
          </li>
          <li className={styles.signOut}>
            <SignOutButton
              data-testid="account-sign-out"
              className={styles.signOutButton}
              onClick={handleLinkClick}
              onBlur={handleBlur}
              onFocus={handleFocus}
              width="full"
            />
          </li>
        </ul>
      </div>
    </nav>
  );
});

MyAccount.displayName = 'MyAccount';

MyAccount.propTypes = {};

MyAccount.defaultProps = {};

export default MyAccount;
