import { FORMAT, dayjs } from 'utils/date';

const isEmpty = value => value === undefined || value === null || value === '';

const otherPostcodeRegex = /^[A-Z\d\s]+$/i;

export function otherPostcode(value) {
  if (!isEmpty(value) && !otherPostcodeRegex.test(value)) {
    return 'Invalid postcode';
  }
  return undefined;
}

export function postcode(value) {
  const postcodeRegex =
    /^([G][I][R] 0[Aa]{2})$|^[A-PR-UWYZ](([A-HK-Y]?\d\d?)|(\d[A-HJKPSTUW])|([A-HK-Ya-hk-y]\d[ABEHMNPRV-Y]))\s?\d[ABD-HJLNP-UW-Z]{2}$/i;
  if (!isEmpty(value) && (!otherPostcodeRegex.test(value) || !postcodeRegex.test(value))) {
    return 'Invalid UK postcode';
  }
  return undefined;
}

export function usZipCode(value) {
  const usZipCodeRegex = /^\d{5}$/;
  if (!isEmpty(value) && (!otherPostcodeRegex.test(value) || !usZipCodeRegex.test(value))) {
    return 'Invalid US ZIP code';
  }
  return undefined;
}

export function frPostcode(value) {
  const frZipCodeRegex = /^\d{5}$/;
  if (!isEmpty(value) && (!otherPostcodeRegex.test(value) || !frZipCodeRegex.test(value))) {
    return 'Invalid French postcode';
  }
  return undefined;
}

export function iePostcode(value) {
  const ieZipCodeRegex = /^([AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/i;
  if (!isEmpty(value) && (!otherPostcodeRegex.test(value) || !ieZipCodeRegex.test(value))) {
    return 'Invalid Irish postcode';
  }
  return undefined;
}

export function phone(value) {
  const phoneRegex = /^(\+\d{1,3}\s?)?(\(\d+\)\s?)?\d[\d\s-]+$/;
  if (value && !phoneRegex.test(value)) {
    return 'Invalid phone number';
  }
  return undefined;
}

export function nameField(nameType) {
  return value => {
    if (isEmpty(value)) {
      return `Please enter your ${nameType}`;
    }
    const nameRegex = /^[0-9a-zA-Z\s\-'.]+$/;
    if (!nameRegex.test(value)) {
      return `Invalid ${nameType}`;
    }
    return undefined;
  };
}

export function isNotFutureDate(value) {
  if (isEmpty(value)) {
    return undefined;
  }

  if (value.length < 8) {
    return 'Please insert full date';
  }

  const parsedDate = dayjs(value, [FORMAT.DAY_MONTH_YEAR, FORMAT.DAY_MONTH_2YEAR], false);

  if (!parsedDate.isValid()) {
    return 'Please insert full date';
  }

  const isAfter = parsedDate.isAfter(dayjs(), 'day');

  if (isAfter) {
    return "The date can't be in the future";
  }

  return undefined;
}

const DATE_FORMAT = /[0-9]{1,2}[^0-9a-z][0-9]{1,2}[^0-9a-z][0-9]{2,4}/i;
const DATE_SEPARATOR = /[^0-9a-z]+/g;

/**
 *
 * @param {string} value date string to convert
 * @returns {Date | undefined} Converted date, original string if conversion not possible or false if failed conversion
 */
export function convertToDate(value) {
  if (value.length < 6 || value.length > 10 || !DATE_FORMAT.test(value)) {
    return undefined;
  }

  const dateParts = value.split(DATE_SEPARATOR);
  if (dateParts.length !== 3) {
    return undefined;
  }
  const yy = dateParts[2];
  const mm = dateParts[1];
  const dd = dateParts[0];

  if (yy.length !== 2 && yy.length !== 4) {
    return undefined;
  }

  let yyyy = yy;
  if (yy.length === 2) {
    yyyy = yy < 90 ? `20${yy}` : `19${yy}`;
  }

  if (mm < 1 || mm > 12) {
    return undefined;
  }
  if (dd < 1 || dd > 31) {
    return undefined;
  }

  const parsedDate = dayjs(
    `${dd.padStart(2, '0')}-${mm.padStart(2, '0')}-${yyyy}`,
    FORMAT.DAY_MONTH_YEAR,
    true,
  );
  if (parsedDate.isValid()) {
    return parsedDate.toDate();
  }

  return undefined;
}

export function minLength(min) {
  return value => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }
    return undefined;
  };
}

export function minLengthNumeric(min) {
  return value => {
    if (!isEmpty(value)) {
      const numericCharacters = value.replace(/\D/g, '');

      if (numericCharacters.length < min) {
        return `Must be at least ${min} numeric characters`;
      }
    }
    return undefined;
  };
}

export function addressLine(value) {
  const lineRegex =
    /^[a-zA-Z\d\s\-'.,/!&\u00C0-\u00C2\u00C4\u00C8-\u00CF\u00D2-\u00D4\u00D6\u00D9-\u00DC\u00DD\u00E0-\u00E2\u00E4\u00E8-\u00EF\u00F2-\u00F4\u00F6\u00F9-\u00FD\u00FF\u0174-\u0178\u1E80-\u1E85\u1EF2\u1EF3]+$/;
  if (!lineRegex.test(value) && !isEmpty(value)) {
    return `Invalid address line`;
  }
  return undefined;
}

export function townRegionLine(value) {
  const townRegionRegex = /^[a-zA-Z\d\s\-'.,!&]+$/;
  if (!townRegionRegex.test(value) && !isEmpty(value)) {
    return `Invalid address line`;
  }
  return undefined;
}

export function required(value) {
  if (isEmpty(value)) {
    return 'Required';
  }
  return undefined;
}

export function maxLength(max) {
  return value => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }
    return undefined;
  };
}

export function maxLengthNumericPhone(max) {
  return value => {
    if (!isEmpty(value)) {
      // WPIP-26745 - if phone number has '+' character in front, then the maximum number is smaller with one character
      const maxValue = value[0] === '+' ? max - 1 : max;
      const numericCharacters = value.replace(/\D/g, '');

      if (numericCharacters.length > maxValue) {
        return `Must be no more than ${maxValue} numeric characters`;
      }
    }
    return undefined;
  };
}

export function objHasKeys(obj, keys) {
  if (obj === undefined || obj === null) {
    return false;
  }
  const next = keys.shift();
  return obj[next] !== undefined && (!keys.length || objHasKeys(obj[next], keys));
}

export function isDefined(property) {
  return property !== undefined && property !== null;
}

export function myWaitroseCardNumber(value) {
  const myWaitroseCardNumberRegex = /^(9210|9230)([0-9]{12})$/;
  if (!myWaitroseCardNumberRegex.test(value)) {
    return `The number should start 9210 or 9230 and contain 16 digits`;
  }
  return undefined;
}

export function alreadyLinkedMsg() {
  return 'This number is already linked to another account';
}

export function addButtonValidator(message) {
  return value => {
    if (isEmpty(value)) {
      return message;
    }
    return undefined;
  };
}

export function orderIdValidator(value) {
  if (!value) {
    return 'Please enter an order number';
  }
  if (!/^[0-9]{0,20}$/.test(value)) {
    return 'Order numbers only contain numbers and are up to 20 digits long';
  }
  return undefined;
}

export function validateProductName(value) {
  if (isEmpty(value)) {
    return 'Please enter the product name';
  }
  return undefined;
}

export function validateDateUsed(value, formValues) {
  const { dateVisit } = formValues;
  const productBoughtDateTime = formValues?.product?.productBoughtDateTime || dateVisit;

  if (productBoughtDateTime && !isEmpty(productBoughtDateTime) && !isEmpty(value)) {
    const result = dayjs(convertToDate(productBoughtDateTime)).isSameOrBefore(convertToDate(value));
    if (!result) {
      return `the date used must be on or after the date you visited the shop.`;
    }
  }
  return undefined;
}

export const validateIsQuantity = value => {
  if (Number(value)) {
    return undefined;
  }

  return 'Please enter the missing product quantity';
};

export function validateTextArea(value) {
  const regex = /[^a-zA-Z\d\s\-'_.,()&?!@£%/+]+/g;

  if (value && regex.test(value)) {
    return 'Invalid characters. Only the following characters are allowed: A to Z and - _ . , ( ) & ? ! @ £ % / +';
  }
  return undefined;
}
