import {
  CHECKOUT_DEFAULT_ERROR_DESCRIPTION,
  CHECKOUT_DEFAULT_ERROR_TITLE,
} from 'constants/checkout/errorMessages';
import urls from 'constants/urls';
import { openModal } from 'redux/modules/common-modal/actions';
import { preserveStubsUrl } from 'utils/general';
import locator from 'utils/locator';

export default errorResponse => dispatch => {
  const status = errorResponse?.status;
  const code = errorResponse?.response?.body?.code;

  if (status === 409 && code === 'ORDER_ORCHESTRATION_008') {
    dispatch(
      openModal({
        id: 'checkout-error-order-state-conflict',
        severity: 'conflict',
        messageIsHtml: true,
        useIngredientsModal: false,
        messageText: [CHECKOUT_DEFAULT_ERROR_DESCRIPTION],
        titleText: CHECKOUT_DEFAULT_ERROR_TITLE,
        buttons: [
          {
            buttonText: 'Return to Groceries',
          },
        ],
        onCloseCallback: () => {
          locator.href = preserveStubsUrl(urls.groceriesHome);
        },
        webview: {
          id: 'checkout-error-state-conflict',
          ctas: [urls.groceriesHome],
        },
      }),
    );

    return true;
  }

  return false;
};
