import urls from 'constants/urls';
import { openModal } from 'redux/modules/common-modal/actions';
import locator from 'utils/locator';
import { preserveStubsUrl } from 'utils/general';
import { isBookedSlotACollectionSlot } from '../../selectors/get-order-slot-selectors';

const getGiftCardDuplicateErrorModal = () => ({
  titleText: 'Gift card already applied',
  messageText: ['This gift card has already been applied to this order'],
  buttons: [
    {
      buttonText: 'Close',
    },
  ],
  webview: {
    id: 'checkout-error-gift-card-not-live',
  },
});

const getGiftCardNotLiveErrorModal = () => ({
  messageText: [
    'Your gift card cannot be added. This order is no longer live or has been cancelled',
  ],
  webview: {
    id: 'checkout-error-gift-card-not-live',
  },
});

const getGiftCardBookSlotErrorModal = () => ({
  messageText: ["You'll need to book a slot before adding a gift card"],
  buttons: [
    {
      buttonText: 'Book a slot',
      buttonValue: 'OK',
      primary: true,
    },
    {
      buttonText: 'Cancel',
      buttonValue: 'CANCEL',
      secondary: true,
    },
  ],
  onCloseCallback: value => {
    if (value === 'OK') {
      locator.href = preserveStubsUrl(urls.serviceSelection);
    }
  },
  webview: {
    id: 'checkout-error-7',
    ctas: [urls.serviceSelection, null],
  },
});

const getGiftCardTimedOutErrorModal = isCollectionSlot => ({
  messageText: [
    isCollectionSlot
      ? 'Your collection slot has been timed out. Please rebook'
      : 'Your delivery slot has been timed out. Please rebook',
  ],
  buttons: [
    {
      buttonText: 'Book a slot',
      buttonValue: 'OK',
      primary: true,
    },
    {
      buttonText: 'Cancel',
      buttonValue: 'CANCEL',
      secondary: true,
    },
  ],
  onCloseCallback: value => {
    if (value === 'OK') {
      locator.href = preserveStubsUrl(
        isCollectionSlot ? urls.bookCollectionSlot : urls.bookDeliverySlot,
      );
    }
  },
  webview: isCollectionSlot
    ? {
        id: 'checkout-error-8',
        ctas: [urls.bookCollectionSlot, null],
      }
    : {
        id: 'checkout-error-9',
        ctas: [urls.bookDeliverySlot, null],
      },
});

const getEntertainingCollectionErrorModal = () => ({
  messageText: ['Gift cards cannot be added to Entertaining collection orders'],
  webview: {
    id: 'checkout-error-gift-card-not-live',
  },
});

const getInvalidErrorModal = () => ({
  titleText: "That doesn't look quite right",
  messageText: ['Please check the gift card details you entered'],
  buttons: [
    {
      buttonText: 'Close',
    },
  ],
  webview: {
    id: 'checkout-error-gift-card-not-live',
  },
});

const getBalanceMismatchErrorModal = () => ({
  titleText: 'Gift card already used',
  messageText: ['The balance on this gift card is £0.00'],
  webview: {
    id: 'checkout-error-gift-card-not-live',
  },
});

const getRingfencedErrorModal = orderErrorType =>
  orderErrorType === 'ADD_GIFT_CARD_ERROR'
    ? {
        titleText: 'Cannot add card',
        messageText: [
          'Sorry, your gift card has already been redeemed against your order so cannot be added.',
        ],
        webview: {
          id: 'checkout-error-gift-card-ballance-error',
        },
      }
    : {
        titleText: 'Cannot remove card',
        messageText: [
          'Sorry, your gift card has already been redeemed against your order so cannot be removed.',
        ],
        webview: {
          id: 'checkout-error-gift-card-ballance-error',
        },
      };

const getDefaultErrorModal = orderErrorType => {
  const isAddError = orderErrorType === 'ADD_GIFT_CARD_ERROR';
  return {
    titleText: isAddError ? 'Gift card not added' : 'Gift card not removed',
    messageText: [
      isAddError
        ? 'An error occurred while applying your gift card. Please try again'
        : "Sorry, there's been a problem removing your gift card. Please try again or contact Customer Care",
    ],
    buttons: [
      {
        buttonText: 'Try again',
      },
    ],
    webview: {
      id: isAddError ? 'checkout-error-10' : 'checkout-error-11',
      ctas: [null, urls.customerServiceContactUsPage],
    },
  };
};

const getErrorModal = (error, isCollectionSlot) => {
  const orderErrorType = error?.response?.body?.type;

  if (error?.status === 400) {
    return getGiftCardDuplicateErrorModal();
  }

  switch (error?.response?.body?.code) {
    case 'ORDER_PAYMENT_004':
      return getGiftCardNotLiveErrorModal();
    case 'ORDER_PAYMENT_029':
      return getGiftCardBookSlotErrorModal();
    case 'ORDER_PAYMENT_058':
      return getGiftCardTimedOutErrorModal(isCollectionSlot);
    case 'ORDER_PAYMENT_076':
      return getEntertainingCollectionErrorModal();
    case 'ORDER_PAYMENT_077':
      return getInvalidErrorModal();
    case 'ORDER_PAYMENT_082':
      return getBalanceMismatchErrorModal();
    case 'ORDER_PAYMENT_093':
      return getRingfencedErrorModal(orderErrorType);
    default:
      return getDefaultErrorModal(orderErrorType);
  }
};

export default error => (dispatch, getState) => {
  const orderErrorType = error?.response?.body?.type;

  if (orderErrorType === 'ADD_GIFT_CARD_ERROR' || orderErrorType === 'REMOVE_GIFT_CARD_ERROR') {
    const isCollectionSlot = isBookedSlotACollectionSlot(getState());

    dispatch(
      openModal({
        useIngredientsModal: true,
        ...getErrorModal(error, isCollectionSlot),
        includesCustomerCareParagraph: false,
        isOpen: true,
        id: 'checkout-error-gift-card',
        severity: 'conflict',
      }),
    );

    return true;
  }

  return false;
};
