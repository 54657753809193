import React, { memo } from 'react';

import classNames from 'classnames';
import { ChevronRight } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import { ModalCloseLabels, ModalCloseOptions } from 'constants/modalCloseOptions';
import ListModal from 'components/Modal/ListModal';

// [SSR][OK] This should be ok as it is not rendered on load
// eslint-disable-next-line no-restricted-imports
import { mediaBreakpointUp } from 'utils/mediaQueries';

import styles from './MultiSearchModal.scss';
import { useModal } from '../Hooks/use-modal';

type multiSearchButtonsProps = {
  enableSaveButton: boolean;
};

type MultiSearchModalProps = {
  hidden?: boolean;
  mobile?: boolean;
  onToggleModal?: () => unknown;
  onSearch?: () => unknown;
};

const MAX_LENGTH = 100;

export const multiSearchButtons = ({ enableSaveButton }: multiSearchButtonsProps) => {
  const ctaButtons = [
    {
      buttonText: ModalCloseLabels.CLEAR_LIST,
      buttonValue: ModalCloseOptions.CLEAR_LIST,
      disabled: !enableSaveButton,
      theme: 'secondary',
    },
    {
      buttonText: ModalCloseLabels.SEARCH,
      buttonValue: ModalCloseOptions.SAVE,
      disabled: !enableSaveButton,
      theme: 'primary',
    },
  ];
  return mediaBreakpointUp('sm') ? ctaButtons : ctaButtons.reverse();
};

const MultiSearchModal = memo(
  ({
    hidden = true,
    mobile = false,
    onToggleModal = () => {},
    onSearch = () => {},
  }: MultiSearchModalProps) => {
    const { text, modalOpen, toggleModal, onTextChange, onClose } = useModal({
      onToggleModal,
      onSearch,
    });

    const buttonProps = {
      'aria-label': 'Search multiple items',
      className: classNames({
        [styles.multiSearch]: !mobile,
        [styles.mobileMultiSearch]: mobile,
        [styles.hidden]: hidden,
      }),
      'data-testid': 'multi-search-button',
      disabled: hidden,
      onClick: toggleModal,
      role: 'button',
      tabIndex: 0,
    };

    const modalProps = {
      customButtons: multiSearchButtons,
      flexButtons: true,
      isOpen: modalOpen,
      label: `Multiple item search. Press Enter after each item.`,
      maxListLength: MAX_LENGTH,
      mobile,
      onClose,
      onTextChange,
      text,
      placeholder: 'Type or paste your list here',
      titleText: 'Multi-search',
    };

    return (
      <>
        <button {...buttonProps} type="button">
          Multi-search
        </button>
        {mobile && <ChevronRight size="xsmall" className={styles.chevronRightIcon} />}
        <ListModal {...modalProps} />
      </>
    );
  },
);

MultiSearchModal.displayName = 'MultiSearchModal';

export default MultiSearchModal;
