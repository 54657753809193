import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useWtrSelector } from 'redux/hooks';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import isEqual from 'lodash/isEqual';

import env from 'env/env';
import MyWaitrosePopover from 'components/ProductPod/MyWaitrose';
import { dataLayer } from 'analytics/data-layer';

import { getCurrentPromotionPage } from 'redux/modules/search-and-browse/selectors/promotions';
import { getPromotionById } from 'redux/modules/entities/selectors/promotions';

import { dataAttributesGenerator } from 'utils/data-attribute-generator';
import { getFeatureFlags } from 'utils/feature-flags';
import { promotionCodeMapper } from 'components/Product/Promotion/PromotionCodeMapper';
import PromotionDescription from './Description';
import { usePromotion } from './use-promotion';

import styles from './index.scss';

export const trackOfferClick = name => () =>
  dataLayer.push({ event: 'product_promo_clicked', promotion: { name } });

const promoRegex = /(([aA]dd) [1-9][0-9]* (for)){1}/;
const addRegex = /([aA]dd) /;
const ariaLabel = description =>
  promoRegex.test(description) ? description.replace(addRegex, '') : description;

const PromotionElement = memo(
  ({ miniPromo, productId, promotionId, qualifier, showIcon, showMyWaitrose, isTrolley }) => {
    const promotion = useWtrSelector(state => getPromotionById(state, promotionId), isEqual) || {};

    const currentPromotionPage = useWtrSelector(
      state => getCurrentPromotionPage(state, promotion),
      isEqual,
    );

    const {
      hidden,
      myWaitrosePromotion,
      promotionTypeCode,
      promotionDescription,
      promoPath = `/shop/offers/${promotionId}`,
    } = promotion;

    const { mealDealId } = useParams();

    const promoCodeString = promotionCodeMapper(promotionTypeCode);

    const { backgroundColor, isLink, withChevron } = usePromotion({
      promotionTypeCode,
      currentPromotionPage,
    });

    if (!promotionDescription || !!mealDealId || (hidden && !isTrolley)) return null;

    const description = (
      <PromotionDescription
        {...{
          miniPromo,
          productId,
          promotion,
          promotionId,
          underlineText: !currentPromotionPage,
          noMargin: !!(showMyWaitrose || backgroundColor),
          backgroundColor,
          withChevron,
          isLink,
          isTrolley,
        }}
      />
    );
    let offerClasses;
    let promoElement;

    if (showIcon) {
      offerClasses = classNames(styles.offer, {
        // if myWaitrose, then show myWaitrose Icon. Otherwise, show pound icon.
        [styles.myWaitrose]: myWaitrosePromotion,
        [styles.poundSignIcon]: !myWaitrosePromotion,
        [styles.withBackground]: !!backgroundColor,
        [styles.trolleyPage]: isTrolley,
      });
    } else {
      offerClasses = classNames(styles.offerMyWaitrose, {
        [styles.withBackground]: backgroundColor,
        [styles.green]: backgroundColor === 'green',
        [styles.trolleyPage]: isTrolley,
      });
    }
    if (isLink) {
      const dataAttributes = dataAttributesGenerator({
        actiontype: 'redirect',
        origincomponent: 'PromotionElement',
        shortdescription: `view products in ${promotionDescription} offer`,
        test: 'link-offer',
        'cs-override-id': promoCodeString ? `promo-text-${promoCodeString}` : undefined,
      });
      promoElement = (
        <TextLink
          underline="never"
          aria-label={ariaLabel(promotionDescription)}
          component={Link}
          {...dataAttributes}
          to={`${env.root}${promoPath}`}
          onClick={trackOfferClick(promotionDescription)}
          rel={getFeatureFlags().browse_offersLinksWithRelNofollow ? 'nofollow' : undefined}
        >
          {description}
        </TextLink>
      );
    } else {
      promoElement = description;
    }

    return (
      <div className={offerClasses} id={`${productId}_${promotionId}${qualifier}`}>
        {showMyWaitrose && <MyWaitrosePopover promotion={promotion} />}
        {promoElement}
      </div>
    );
  },
);

PromotionElement.propTypes = {
  miniPromo: PropTypes.bool,
  productId: PropTypes.string,
  promotionId: PropTypes.number,
  qualifier: PropTypes.string,
  searchType: PropTypes.string,
  showIcon: PropTypes.bool,
  showMyWaitrose: PropTypes.bool,
  isTrolley: PropTypes.bool,
};

PromotionElement.defaultProps = {
  miniPromo: false,
  productId: '',
  promotionId: '',
  qualifier: '',
  searchType: null,
  showIcon: true,
  showMyWaitrose: false,
  isTrolley: false,
};

PromotionElement.displayName = 'PromotionElement';

export default PromotionElement;
