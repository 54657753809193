import React, { useRef, MutableRefObject } from 'react';
import { AccordionItem } from '@johnlewispartnership/wtr-ingredients/ingredients/Accordion';
import { useWtrSelector } from 'redux/hooks';
import AccordionRecipeTitle from './AccordionRecipeTitle';
import styles from './index.scss';
import RecipesProductGrid from '../../Content/Shoppable/ProductGrid';
import type { RecipeSummaryItem } from '../../../../api/definitions/recipes';
import {
  getNumberOfSelectedShoppableProducts,
  getNumberOfShoppableProducts,
} from '../../../../redux/modules/recipes/selectors';

export type ToggleIngredientsRefType = MutableRefObject<Record<string, (checked: boolean) => void>>;

export interface RecipeAccordionItemProps {
  recipe: RecipeSummaryItem;
}

const RecipeAccordionItem = ({ recipe }: RecipeAccordionItemProps) => {
  const numberOfShoppableProducts = useWtrSelector(state =>
    getNumberOfShoppableProducts(state, recipe.id),
  );

  const numberOfSelectedShoppableProducts = useWtrSelector(state =>
    getNumberOfSelectedShoppableProducts(state, recipe.id),
  );

  const toggleIngredientsRef = useRef<Record<string, (checked: boolean) => void>>({});

  const toggleAllIngredients = (checked: boolean) => {
    Object.values(toggleIngredientsRef.current).forEach(toggle => {
      if (toggle) {
        toggle(checked);
      }
    });
  };

  if (numberOfShoppableProducts === 0) return null;
  return (
    <AccordionItem
      title={
        <AccordionRecipeTitle
          recipeId={recipe.id}
          title={recipe.title}
          checked={numberOfSelectedShoppableProducts > 0}
          toggleIngredients={toggleAllIngredients}
        />
      }
      id={recipe.id}
      defaultExpanded={false}
      contentClassName={styles.recipesAccordionItem}
      data-testid={`recipe-accordion-item-${recipe.id}`}
      headingElement="div"
    >
      <RecipesProductGrid
        recipeId={recipe.id}
        showSwapProduct={() => {}}
        setCurrentIngredientIndex={() => {}}
        currentSwappedProduct={null}
        toggleIngredientsRef={toggleIngredientsRef}
        forcedHideSwapLink
      />
    </AccordionItem>
  );
};

export default RecipeAccordionItem;
