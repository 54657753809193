import React, { useCallback, useMemo } from 'react';
import { useMakeSelector } from 'hooks/use-make-selector';
import {
  makeGetCategoryParentInfoByProductId,
  makeGetProductById,
} from 'redux/modules/entities/selectors/products';
import { getLocation, isSuppressAlternatives } from 'redux/modules/routing/selectors';
import { getIsFavourite } from 'redux/modules/favourites-products/selectors';
import { isSponsoredProduct } from 'redux/modules/search-and-browse/selectors';
import { getPageType } from 'utils/get-page-type';
import { viewAlternativesModal } from 'constants/modals';
import { PICKING_REF_VIEW_ALTERNATIVES } from 'constants/picking-ref';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';

import { notifyModalOpen } from 'redux/modules/common-modal/actions';
import { toggleProductModal } from 'redux/modules/trolley/actions/toggle-product-modal';
import { getSubstitutionProducts } from 'redux/modules/trolley/actions/get-substitution-products';

import { useSelector, useDispatch } from 'react-redux';
import { handleProductListClick } from 'utils/gtm';

export interface ViewAlternativesProps {
  productId: string;
  conflictMessage: string;
  position: number;
}

export const trackViewAlternativesClick =
  (
    productData: unknown,
    analyticsData: {
      isFavourite: boolean;
      position: number;
      sponsored: boolean;
      sponsorshipId: string;
      conflictMessage: string;
      wrapperId: string;
    },
  ) =>
  () => {
    handleProductListClick({
      conflictMessage: analyticsData?.conflictMessage?.toLowerCase(),
      isFavourite: analyticsData?.isFavourite,
      productData,
      searchType: analyticsData?.wrapperId,
      wrapperId: analyticsData?.wrapperId,
      position: analyticsData?.position,
      boosted: false,
      sponsored: analyticsData?.sponsored,
      // @ts-expect-error handleProductListClick() has not been typed
      sponsorshipId: analyticsData?.sponsorshipId,
      viewAlternativesClick: true,
    });
  };

const ViewAlternatives = ({ productId, conflictMessage, position }: ViewAlternativesProps) => {
  const dispatch: WtrDispatch = useDispatch();
  const { id, url } = useMakeSelector(makeGetCategoryParentInfoByProductId, productId);
  const { pathname } = useSelector(getLocation);
  const suppressDisplayLink = useSelector(isSuppressAlternatives);
  const productData = useMakeSelector(makeGetProductById, productId);
  const { lineNumber, name, sponsorshipId } = productData;
  // eslint-disable-next-line
  // @ts-ignore: createSelector known issue, where the return function is typed as receiving a single arg
  const isFavourite = useSelector(state => getIsFavourite(state, lineNumber));
  const sponsored = useSelector(state => isSponsoredProduct(state, lineNumber));

  const analyticsData = useMemo(
    () => ({
      isFavourite,
      position,
      sponsored,
      sponsorshipId,
      conflictMessage,
      wrapperId: getPageType(pathname),
    }),
    [conflictMessage, isFavourite, pathname, position, sponsored, sponsorshipId],
  );

  const display = id && pathname !== url && !suppressDisplayLink;

  const getAlternativeProductsCall = useCallback(
    (productLineNumber, origin) =>
      dispatch(getSubstitutionProducts(productLineNumber, origin, PICKING_REF_VIEW_ALTERNATIVES)),
    [dispatch],
  );
  const toggleModal = useCallback(
    (productLineNumber, isOpen) =>
      dispatch(toggleProductModal(productLineNumber, null, isOpen, viewAlternativesModal.id)),
    [dispatch],
  );
  const notifyModalCall = useCallback(props => dispatch(notifyModalOpen(props)), [dispatch]);

  const handleButtonClick = useCallback(() => {
    toggleModal(lineNumber, true);
    getAlternativeProductsCall(lineNumber, 'view-alternatives');
    notifyModalCall({
      ...viewAlternativesModal,
      title: viewAlternativesModal.titleText,
    });
    dispatch(trackViewAlternativesClick(productData, analyticsData));
  }, [
    toggleModal,
    getAlternativeProductsCall,
    notifyModalCall,
    dispatch,
    lineNumber,
    productData,
    analyticsData,
  ]);

  if (!display) {
    return null;
  }

  return (
    <Button
      data-testid="view-alternatives-toggle"
      onClick={handleButtonClick}
      type="button"
      theme="secondary"
      width="full"
    >
      View alternatives<span className="sr-only">{` for ${name}`}</span>
    </Button>
  );
};

export default ViewAlternatives;
