import orderStatus from 'constants/orderStatus';
import { getCustomerAccount } from 'redux/modules/accounts/selectors/get-customer-account';
import { getPlaceOrderOrigin } from 'redux/modules/checkout/selectors/get-place-order-origin';
import { getPlaceOrderOriginMobileAvailable } from 'redux/modules/checkout/selectors/get-place-order-origin-mobile-available';
import { getSaveNewCard } from 'redux/modules/checkout/selectors/get-save-new-card';
import { getIsInstantCheckoutIsInProgress } from 'redux/modules/instant-checkout/selectors';
import { getOrder } from 'redux/modules/order/selectors';
import {
  getSlotBranchId as getOrderSlotBranchId,
  getSlotType as getOrderSlotType,
} from 'redux/modules/order/selectors/get-order-slot-selectors';
import {
  getEstimatedBalanceToPay,
  getEstimatedTotals,
} from 'redux/modules/order/selectors/get-order-totals';
import {
  getGiftCards,
  getGiftVouchers,
} from 'redux/modules/order/selectors/gift-cards-and-vouchers';
import { getPartnerDiscountCardNumber } from 'redux/modules/order/selectors/partner-discount';
import {
  getPromoCodesList,
  getQualifiedPromoCodesInline,
} from 'redux/modules/order/selectors/promo-codes';
import getFirstSavedPaymentCard from 'redux/modules/payment/selectors/get-first-saved-payment-card';
import { getCustomerOrderId, getCustomerOrderState } from 'redux/modules/sessions/selectors';
import { canInstantCheckout } from 'redux/modules/trolley/selectors/can-instant-checkout';
import { getTrolleyItems } from 'redux/modules/trolley/selectors/get-items';
import {
  getCustomerSlotBranchId,
  getSlotType as getCustomerSlotType,
} from 'redux/modules/customer-slot/selectors';
import { getDeliveryCharge } from 'redux/modules/trolley/selectors/get-totals';
import { SlotTypes } from 'constants/fulfilmentTypes';
import { getPaymentTypes } from '../utilities/get-payment-types';
import trolleyItemToAnalyticsProduct from '../utilities/trolley-item-to-analytics-product';

type EventType =
  | { event: 'transaction_completed'; [key: string]: any }
  | {
      event: 'redeem_gift_voucher';
      gift_voucher_number: string;
    }
  | { event: 'redeem_promo_code'; promo_code: string }
  | { event: 'redeem_gift_card'; gift_card_number: string };

function getOptionFieldValue(isAmend: boolean, isInstantCheckout: boolean) {
  if (isInstantCheckout) {
    return 'instant checkout amend';
  }

  return isAmend ? 'amend' : 'purchase';
}

function getSaveNewCardValue(
  isInstantCheckout: boolean,
  saveNewCard: boolean | undefined,
  isEntertainingCollectionSlot: boolean,
) {
  if (isEntertainingCollectionSlot) {
    return 'no_card';
  }

  if (isInstantCheckout || saveNewCard === undefined) {
    return 'using_saved_card';
  }

  return saveNewCard ? 'save_card' : 'dont_save_card';
}

export default (state: WtrState) => {
  // Depends on state: instantCheckout
  const isInstantCheckout = getIsInstantCheckoutIsInProgress(state);

  // Depends on state: trolley
  const trolleyItems = getTrolleyItems(state);
  const trolleyItemMapper = trolleyItemToAnalyticsProduct(state);
  const instantCheckoutEligibility = isInstantCheckout || canInstantCheckout(state);

  // Depends on state: trolley or order
  const deliveryCharge = isInstantCheckout
    ? getDeliveryCharge(state)
    : getEstimatedTotals(state)?.deliveryCharge?.amount;

  // Depends on state: order (only loaded in full checkout via initialise)
  const orderState = !isInstantCheckout ? getOrder(state) : undefined;
  const giftCards = orderState ? getGiftCards(state) : [];
  const giftVouchers = orderState ? getGiftVouchers(state) : [];
  const promoCodes = orderState ? getPromoCodesList(state) : [];
  const partnershipDiscount = orderState ? getPartnerDiscountCardNumber(state) : undefined;
  const placeOrderOrigin = isInstantCheckout ? '' : getPlaceOrderOrigin(state) ?? '';
  let placeOrderOriginMobile;

  if (isInstantCheckout) {
    placeOrderOriginMobile = '';
  } else {
    placeOrderOriginMobile = getPlaceOrderOriginMobileAvailable(state)
      ? 'available'
      : 'unavailable';
  }

  const accountHasSavedCard = isInstantCheckout ? undefined : !!getFirstSavedPaymentCard(state);
  const estimatedBalanceToPay = isInstantCheckout ? 0 : getEstimatedBalanceToPay(state);
  // TODO: We need to check with analytics engineers if they want change this structure now that we have to support multiple promo codes
  const promoCodesInline = isInstantCheckout ? '' : getQualifiedPromoCodesInline(state);
  const paymentType = isInstantCheckout ? '' : getPaymentTypes(state);

  // Depends on state: accounts.account
  const { email, id: customerId } = getCustomerAccount(state);

  // Depends on state: sessions (or order)
  const customerOrderId = getCustomerOrderId(state) ?? orderState?.customerOrderId;
  const isAmend =
    isInstantCheckout ||
    (getCustomerOrderState(state) ?? orderState?.order?.status) === orderStatus.AMENDING;

  // Depends on state: customerSlot (or order)
  const slotBranchId = isInstantCheckout
    ? getCustomerSlotBranchId(state)
    : getOrderSlotBranchId(state);
  const orderSlotType = isInstantCheckout ? getCustomerSlotType(state) : getOrderSlotType(state);

  const saveNewCard = getSaveNewCardValue(
    isInstantCheckout,
    getSaveNewCard(state),
    orderSlotType === SlotTypes.ENTERTAINING_COLLECTION,
  );
  const products = trolleyItems && trolleyItems.map(trolleyItemMapper);
  const optionField = getOptionFieldValue(isAmend, isInstantCheckout);

  const giftCardEvents: EventType[] = giftCards.map(({ serialNumber }) => ({
    event: 'redeem_gift_card',
    gift_card_number: serialNumber,
  }));

  const giftVouchersEvents: EventType[] = giftVouchers
    .filter(({ status }) => status !== 'REDEEMED')
    .map(
      ({ serialNumber }) =>
        ({
          event: 'redeem_gift_voucher',
          gift_voucher_number: serialNumber,
        }) as const,
    );

  const promoCodesEvents: EventType[] = promoCodes
    .filter(({ status }) => status === 'APPLIED')
    .map(({ code }) => ({
      event: 'redeem_promo_code',
      promo_code: code,
    }));

  const partnershipDiscountEvent: EventType[] = partnershipDiscount
    ? [{ event: 'redeem_promo_code', promo_code: partnershipDiscount }]
    : [];

  const currentDate = new Date();

  const allEvents: EventType[] = [
    ...giftCardEvents,
    ...giftVouchersEvents,
    ...promoCodesEvents,
    ...partnershipDiscountEvent,
  ];

  allEvents.push({
    event: 'transaction_completed',
    instant_checkout_eligibility: instantCheckoutEligibility,
    orderDate: currentDate.toISOString(),
    user: {
      email_address: email,
      customerId,
    },
    ecommerce: {
      purchase: {
        actionField: {
          id: customerOrderId,
          affiliation: slotBranchId,
          revenue: estimatedBalanceToPay,
          shipping: deliveryCharge,
          coupon: promoCodesInline,
          option: optionField,
          service_type: orderSlotType,
          payment_type: paymentType,
          account_saved_card: accountHasSavedCard,
          save_new_card: saveNewCard,
          place_order_origin: placeOrderOrigin,
          place_order_origin_mobile: placeOrderOriginMobile,
        },
        products,
      },
    },
  });

  return allEvents;
};
