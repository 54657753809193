import { DEFAULT_BUTTON_VALUE } from 'constants/checkout/errorMessages';
import urls from 'constants/urls';
import { openModal } from 'redux/modules/common-modal/actions';
import { preserveStubsUrl } from 'utils/general';
import locator from 'utils/locator';

export default errorResponse => dispatch => {
  const status = errorResponse?.status;
  const code = errorResponse?.response?.body?.code;

  if (status === 409 && code === 'ORDER_ORCHESTRATION_042') {
    const onCloseCallback = () => {
      locator.href = preserveStubsUrl(urls.trolleyPage);
    };

    dispatch(
      openModal({
        id: 'checkout-error-minimum-spend-requirement',
        severity: 'conflict',
        buttons: [
          {
            buttonText: 'OK',
            buttonValue: DEFAULT_BUTTON_VALUE,
            primary: true,
          },
        ],
        cannotClose: true,
        messageText: ['To checkout with this order, please meet the minimum spend requirements.'],
        titleText: 'Minimum spend not met',
        webview: {
          id: 'checkout-error-16',
          ctas: [urls.trolleyPage],
        },
        useIngredientsModal: false,
        onCloseCallback,
      }),
    );

    return true;
  }

  return false;
};
