import { rootPath } from 'utils/root-path';

export type IURLSearch = {
  pathname: string;
  search: string | null;
};

export const urlParser = (url: string | IURLSearch) => {
  switch (typeof url) {
    case 'string':
      return !url.startsWith('http') ? rootPath(url.toLowerCase()) : url;
    case 'object': {
      const { pathname, search } = url;
      return search ? pathname + search : pathname;
    }
    default:
      return url;
  }
};
