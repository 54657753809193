/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { MutableRefObject, useEffect, useState, MouseEvent, useCallback } from 'react';
import classNames from 'classnames';

import { KEY_DOWN, KEY_UP, KEY_ENTER, KEY_DELETE, KEY_TAB } from 'constants/keys';

import {
  deleteSearchHistory,
  getSavedSearchTerms,
  removeSavedSearchTerm,
} from 'components/Search/SearchForm/save-search-term';
import ScreenReaderAnnouncement from 'components/ScreenReaderAnnouncement';
import { Bin } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import { dataLayer } from 'analytics/data-layer';

import styles from 'components/Search/SearchHistory/SearchHistory.scss';

const NUMBER_OF_TERMS_TO_SHOW = 6;

type Props = {
  onSelectedChange: (value: number | null) => void;
  onSelectTerm: (value: string) => void;
  homepage: boolean;
  className?: string;
  inputRef?: MutableRefObject<HTMLInputElement> | null;
  searchType?: string | null;
};

const SearchHistory = ({
  onSelectedChange = () => {},
  onSelectTerm = () => {},
  homepage = false,
  className = '',
  inputRef = null,
  searchType = null,
}: Props) => {
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleTermClick = useCallback(
    (term: string) => {
      onSelectTerm(term);

      dataLayer.push({
        event: 'search_history_item_click',
        eventAction: 'SearchType:SearchHistory',
        eventCategory: 'Search',
        eventLabel: `SearchTerm:${term}`,
        eventValue: undefined,
      });
    },
    [onSelectTerm],
  );

  const handleTermRemove = useCallback(
    (event: MouseEvent | KeyboardEvent, term: string) => {
      event.stopPropagation();
      removeSavedSearchTerm(term, searchType);

      setSearchTerms(getSavedSearchTerms(NUMBER_OF_TERMS_TO_SHOW, searchType));

      dataLayer.push({
        event: 'search_history_item_remove',
        eventAction: 'SearchType:RemoveSearchHistoryTerm',
        eventCategory: 'Search',
        eventLabel: `SearchTerm:${term}`,
        eventValue: undefined,
      });
    },
    [searchType],
  );

  const handleClearAllClick = useCallback(() => {
    deleteSearchHistory(searchType);

    setSearchTerms(getSavedSearchTerms(NUMBER_OF_TERMS_TO_SHOW, searchType));

    dataLayer.push({
      event: 'search_history_clear_all',
      eventAction: 'SearchType:SearchHistoryReset',
      eventCategory: 'Search',
      eventLabel: `SearchHistoryReset`,
      eventValue: undefined,
    });
  }, [searchType]);

  const handleTermKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.keyCode) {
        case KEY_DOWN: {
          if (selectedIndex === null) {
            setSelectedIndex(0);
            onSelectedChange(0);
            break;
          }

          const newSelectedIndex = selectedIndex + 1;
          if (newSelectedIndex < searchTerms.length) {
            setSelectedIndex(newSelectedIndex);
            onSelectedChange(newSelectedIndex);
          }
          break;
        }
        case KEY_UP: {
          const newSelectedIndex = (selectedIndex || 0) - 1;
          if (newSelectedIndex >= 0) {
            setSelectedIndex(newSelectedIndex);
            onSelectedChange(newSelectedIndex);
          } else {
            setSelectedIndex(null);
            onSelectedChange(null);
          }
          break;
        }
        case KEY_ENTER: {
          if (selectedIndex !== null) {
            handleTermClick(searchTerms[selectedIndex]);
          }
          break;
        }
        case KEY_TAB: {
          setSearchTerms([]);
          break;
        }
        case KEY_DELETE: {
          if (selectedIndex !== null) {
            handleTermRemove(event, searchTerms[selectedIndex]);
          }
          break;
        }
        default:
      }
    },
    [handleTermClick, handleTermRemove, onSelectedChange, searchTerms, selectedIndex],
  );

  useEffect(() => {
    setSearchTerms(getSavedSearchTerms(NUMBER_OF_TERMS_TO_SHOW, searchType));
  }, [searchType]);

  useEffect(() => {
    const ref = inputRef?.current;
    if (ref && ref.addEventListener) {
      ref.addEventListener('keydown', handleTermKeyDown);
    }

    return () => {
      if (ref && ref.removeEventListener) {
        ref.removeEventListener('keydown', handleTermKeyDown);
      }
    };
  }, [handleTermKeyDown, inputRef]);

  return searchTerms.length ? (
    <div
      className={classNames(styles.searchHistoryContainer, {
        [styles.homepage]: homepage,
        [className]: !!className,
      })}
    >
      <div className={styles.label} id="search-history-label">
        <span>Your recent searches</span>
        <button
          className={styles.clearAll}
          data-testid="search-history-clear-all"
          onClick={handleClearAllClick}
          type="button"
        >
          Clear all
        </button>
      </div>
      <ul aria-labelledby="search-history-label" id="search-history" role="listbox">
        {searchTerms.map((term, index) => (
          <li
            aria-selected={index === selectedIndex}
            className={classNames({ [styles.selected]: index === selectedIndex })}
            data-testid="search-history-term"
            id="search-history-item"
            key={term}
            onClick={() => handleTermClick(term)}
            role="option"
          >
            <span className={styles.searchTerm}>{term}</span>

            <button
              className={styles.removeButton}
              data-testid="search-history-term-remove"
              onClick={event => handleTermRemove(event, term)}
              type="button"
            >
              <Bin size="medium" />
            </button>
          </li>
        ))}
      </ul>
      <ScreenReaderAnnouncement
        polite
        message={`You have: ${searchTerms.length} previous search terms found.`}
      />
    </div>
  ) : null;
};

SearchHistory.displayName = 'SearchHistory';

export default SearchHistory;
