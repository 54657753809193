import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { C62, weightOptions, GRM, KGM } from 'constants/weightOptions';
import Conflict from 'components/Conflict';
import { ShoppableItem } from 'redux/modules/recipes/index.d';
import { LabeledControl } from '@johnlewispartnership/wtr-ingredients/ingredients/LabeledControl';
import { Radio } from '@johnlewispartnership/wtr-ingredients/ingredients/Radio';
import { useMakeSelector } from 'hooks/use-make-selector';
import { makeGetAvailableUnits } from 'redux/modules/entities/selectors/products';
import { formatAmount } from 'components/ProductPod/TrolleyControls/trolleyControlUtils';
import Alert from '@johnlewispartnership/wtr-ingredients/ingredients/Alert';
import { trackShoppableModal } from 'analytics/recipes-tracking';
import styles from './index.scss';
import ItemButtons, { ItemButtonProps } from './ItemButtons';
import { STORE_CUPBOARD } from '..';

export interface ItemControlsProps {
  id: string;
  productName: string;
  displayPrice: string;
  hasConflicts: boolean;
  ingredient: ShoppableItem;
  onUpdate: (ingredient: ShoppableItem) => void;
  onUpdatePrice: (ingredient: ShoppableItem) => void;
  trolleyUom: string;
  recipeId: string;
  displayUOMPrice: string;
  focused: boolean;
}

const ItemControls = ({
  id,
  onUpdate,
  onUpdatePrice,
  productName,
  displayPrice,
  hasConflicts,
  ingredient,
  trolleyUom,
  recipeId,
  displayUOMPrice,
  focused = false,
}: ItemControlsProps) => {
  const { uom, type, duplicateItem, lineNumber } = ingredient;

  const inputRef = useRef<HTMLInputElement>(null);

  const [isEditingAmount, setIsEditingAmount] = useState(false);
  const [isRecentlyUpdated, setRecentlyUpdated] = useState(false);

  const availableUnits = useMakeSelector(makeGetAvailableUnits, id);

  const weightUOMs = availableUnits.filter((unit: string) => unit !== C62);
  const canBuyEach = availableUnits.includes(C62);
  const showUomSelector = !!weightUOMs.length && canBuyEach && !hasConflicts;

  const [desiredUom, setDesiredUom] = useState<string>(uom);

  const trolleyUomHasValue = trolleyUom !== undefined && trolleyUom !== null;

  const disableItemUom = trolleyUomHasValue && trolleyUom !== C62;
  const disableWeightUom = trolleyUomHasValue && trolleyUom === C62;

  useEffect(() => {
    setDesiredUom(uom);
  }, [uom]);

  const {
    label,
    ariaLabel,
    default: defaultQuantity = 0,
    increment,
    minimum,
    maximum,
    unit,
  } = weightOptions[desiredUom as keyof typeof weightOptions];

  const [inputValue, setInputValue] = useState<number>(defaultQuantity);

  const weightUom = weightUOMs?.find((weight: string) => weight === uom) || weightUOMs[0];
  const showWeightUom = desiredUom === weightUom;

  const { amountSelected = defaultQuantity, totalPrice } = ingredient;

  useEffect(() => {
    setInputValue(amountSelected);
  }, [amountSelected]);

  // Sets initial displayPrice
  useEffect(() => {
    onUpdate({
      ...ingredient,
      uom: desiredUom,
      amountSelected:
        amountSelected !== 0 && type !== STORE_CUPBOARD && !hasConflicts ? amountSelected : 0,
      totalPrice: totalPrice || displayPrice,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayPrice]);

  const handleIncrement = () => {
    const newAmount = inputValue + increment;

    if (newAmount <= maximum) {
      onUpdatePrice({
        ...ingredient,
        amountSelected: newAmount,
      });
    }

    trackShoppableModal('click add', recipeId, lineNumber);
  };

  const handleDecrement = () => {
    const newAmount = inputValue - increment;
    trackShoppableModal('click remove', recipeId, lineNumber);

    if (
      (desiredUom === KGM && inputValue === 0.5) ||
      (desiredUom === GRM && inputValue === 100) ||
      newAmount < minimum
    ) {
      onUpdatePrice({
        ...ingredient,
        amountSelected: 0,
      });
      return;
    }

    onUpdatePrice({
      ...ingredient,
      amountSelected: Math.round(newAmount * 10) / 10,
    });
  };

  const selectAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = formatAmount(desiredUom, e.target.value);

    if (+newAmount <= maximum) {
      setInputValue(newAmount as number);
    }

    setIsEditingAmount(true);
  };

  const handleUpdateClick = () => {
    onUpdatePrice({
      ...ingredient,
      amountSelected: inputValue,
    });
    setRecentlyUpdated(true);

    setTimeout(() => {
      setRecentlyUpdated(false);
      setIsEditingAmount(false);
    }, 2000);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleUpdateClick();
    }
  };

  const selectUom = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDesiredUom(e.target.value);

    onUpdatePrice({
      ...ingredient,
      amountSelected: weightOptions[e.target.value as keyof typeof weightOptions].default,
      uom: e.target.value,
    });
  };

  const handleFocus = () => inputRef?.current?.select();

  useEffect(() => {
    if (focused) {
      handleFocus();
    }
  }, [focused]);

  const itemButtonProps: ItemButtonProps = {
    productName,
    isDisabled: (hasConflicts || duplicateItem) as boolean,
    isEditingAmount,
    isRecentlyUpdated,
    handleDecrement,
    handleIncrement,
    handleUpdateClick,
    inputValue,
  };

  if (duplicateItem) {
    return (
      <div data-testid="duplicate" className={styles.wrapper}>
        <div className={styles.duplicate}>
          <Alert type="info" title="Duplicate ingredient">
            Update first occurrence to change quantity
          </Alert>
        </div>
      </div>
    );
  }

  return (
    <div data-testid="item-controls-wrapper" className={styles.wrapper}>
      {hasConflicts ? (
        <div className={styles.conflict} data-testid="conflict">
          <Conflict
            entityId={id}
            entityType="products"
            messageType="shortItem"
            textOnly
            onPDP
            className={styles.conflictItemControl}
          />
        </div>
      ) : (
        <div className={styles.itemControls} data-testid="item-controls">
          <div>
            {showUomSelector && (
              <form className={styles.uom} data-testid="uom-selector">
                <LabeledControl
                  label="item"
                  control={
                    <Radio
                      name={`Add to trolley by item - ${productName}`}
                      value={C62}
                      checked={desiredUom === C62}
                      data-testid="itemUomSelector"
                      onChange={selectUom}
                      disabled={disableItemUom}
                    />
                  }
                />
                <LabeledControl
                  label="weight"
                  control={
                    <Radio
                      name={`Add to trolley by weight - ${productName}`}
                      value={weightUom}
                      checked={desiredUom !== C62}
                      data-testid="weightUomSelector"
                      onChange={selectUom}
                      disabled={disableWeightUom}
                    />
                  }
                />
              </form>
            )}

            <div className={styles.quantityInputs}>
              {/* eslint-disable jsx-a11y/label-has-associated-control */}
              <label
                data-testid="inputProductAmountWrapper"
                className={classNames(styles.inputWrapper, {
                  [styles.showUom]: showWeightUom,
                })}
              >
                {/* eslint-enable jsx-a11y/label-has-associated-control */}
                <input
                  aria-label={ariaLabel}
                  ref={inputRef}
                  className={styles.input}
                  data-testid="inputProductAmount"
                  type="text"
                  inputMode={unit === GRM ? 'numeric' : 'decimal'}
                  value={inputValue}
                  min={minimum}
                  max={maximum}
                  onChange={selectAmount}
                  onKeyDown={handleInputKeyDown}
                  onFocus={handleFocus}
                />
                <div className={styles.inputUom}>{label}</div>
              </label>
              <ItemButtons {...itemButtonProps} />
            </div>
          </div>
          <div className={styles.displayPrice}>
            {totalPrice} <br />
            {displayUOMPrice && (
              <span className={styles.displayUOMPrice} data-testid="displayUOMPrice">
                {displayUOMPrice.replace(/[()]/g, '')}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemControls;
