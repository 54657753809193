import React, { Fragment, memo } from 'react';

import { getMinSuggestChars } from 'components/Search/utils/get-min-suggest-chars';

import styles from './Highlight.scss';

interface Props {
  match: string;
  string: string;
}

const HTML_REGEX = /(<([^>]+)>)/gi;

const Highlight = memo(({ match, string }: Props) => {
  const cleanedString = string.replace(HTML_REGEX, '');
  const searchedWords = match.trim().split(/\s/);
  const suggestionsWords = cleanedString.split(/\s/);
  const minimumCharacters = getMinSuggestChars;

  return (
    <Fragment>
      {suggestionsWords.map(word => {
        const matchFound = searchedWords.find(
          sw => sw.length >= minimumCharacters && word.search(RegExp(sw, 'i')) !== -1,
        );

        if (matchFound) {
          const matchIndex = word.search(RegExp(matchFound, 'i'));

          return (
            <span key={`match-${word}`}>
              {word.slice(0, matchIndex)}
              <strong className={styles.highlight}>
                {word.slice(matchIndex, matchIndex + matchFound.length)}
              </strong>
              {word.slice(matchIndex + matchFound.length)}{' '}
            </span>
          );
        }

        return <span key={`nomatch-${word}`}>{word} </span>;
      })}
    </Fragment>
  );
});

Highlight.displayName = 'Highlight';

export default Highlight;
